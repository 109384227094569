import { Component, ElementRef } from '@angular/core';
import { SharedService } from '@core/services/shared.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-shared-service',
  template: '',
})
export class SharedServiceComponent {

  constructor(
    element: ElementRef,
    sharedService: SharedService,
  ) {
    element.nativeElement.sharedServices = sharedService;
  }
}
