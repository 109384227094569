import { AccountTypeInfo } from "@core/model/accountTypeInfo";
import { BusinessType } from "@core/model/tenant";
import { SelectItem } from "primeng/api";

export function getAccountTypeOptions(businessTypes: BusinessType[]): SelectItem<any>[] {
    return businessTypes.map(bt => ({
        label: bt.displayName ? bt.displayName : bt.businessType,
        value: bt.businessTypeID
      }));

}

export function getAccountTypeNames(businessTypes: BusinessType[]): AccountTypeInfo {
  const result: AccountTypeInfo = {};
  businessTypes.forEach(bt => result[bt.businessType] = bt);
  return result;
}