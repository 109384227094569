import { Inject, Injectable } from '@angular/core';
import { IMfeService, ISharedServices, MessageCallback, ProductNames } from '@mynexus/mfe-core';
import { ElementRegistryService } from '@core/services/element-registry.service';
import { MfeRegistryService } from './mfe-registry.service';
import { AuthenticationService } from '@core/authentication/services/authentication.service';
import { API_URL } from '@core/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService implements ISharedServices {
  private tenantId?: string;

  constructor(
    public elementRegistryService: ElementRegistryService,
    public authenticationService: AuthenticationService,
    private mfeRegistryService: MfeRegistryService,
    @Inject(API_URL) private apiBaseUrl: string,
  ) {
  }
  getAppName(): ProductNames | null {
    return null;
    // throw new Error('Method not implemented.');
  }

  broadcastMessage(_topic: string, _message: any): void {
    // throw new Error('Method not implemented.');
  }

  getMfeService(appName: string): IMfeService {
    return this.mfeRegistryService.getMfeService(appName);
  }

  registerMfeService(appName: string, service: IMfeService): void {
    this.mfeRegistryService.registerMfeService(appName, service);
  }

  getAuthenticationHeader() {
    return {
      name: 'Authorization',
      value: `Bearer ${this.authenticationService.token}`,
    };
  }

  getBaseUrlFor(): string | never {
    // remove trailing slash
    return this.apiBaseUrl?.replace(/\/$/, "");
  }

  getTenantId(): string {
    return this.tenantId || '';
  }

  // Note: this method is not currently used but is required by the interface.
  // In the future, this method can be used in conjunction with the broadcastMessage method 
  // to subscribe to a topic and initiate a callback function when a message is received.
  subscribeToTopic(_topic: string, _callback: MessageCallback): void {
    return;
    //throw new Error('Method not implemented.');
  }

  unsubscribeFromTopic(_topic: string, _callback: MessageCallback): void {
    return;
    //throw new Error('Method not implemented.');
  }
}
