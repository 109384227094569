import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AccountTypes, PacImAccountTypesId } from '@core/constants';
import { IProvider, IProviderSearchResult, IRelevantDataProviders, IRelevantDataUserProviders } from '@core/model';
import { UsersService } from '@core/services';
import { ArrayUtils, ODataService, SecurityService, TableColumn } from '@mynexus/mynexus-ui-lib';
import { cloneDeep, isEmpty, isEqual, pick } from 'lodash-es';
import { ConfirmationService, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-assign-providers-to-user',
  templateUrl: './assign-providers-to-user.component.html',
  styleUrls: ['./assign-providers-to-user.component.scss']
})
export class AssignProvidersToUserComponent implements OnDestroy, OnInit {
  @ViewChild('dtp', { static: true }) dataTableProviders!: Table;
  @ViewChild('dtu', { static: true }) dataTableUserProviders!: Table;

  @ViewChild('providerListMs') providerListMs!: MultiSelect;
  @ViewChild('providerAddressMs') providerAddressMs!: MultiSelect;
  @ViewChild('providerNpiMs') providerNpiMs!: MultiSelect;
  @ViewChild('providerTaxIdMs') providerTaxIdMs!: MultiSelect;
  @ViewChild('userProvidersMs') userProvidersMs!: MultiSelect;
  @ViewChild('userAddressMs') userAddressMs!: MultiSelect;
  @ViewChild('userNpiMs') userNpiMs!: MultiSelect;
  @ViewChild('userTaxIdMs') userTaxIdMs!: MultiSelect;
  @ViewChild('accountMs') accountMs!: MultiSelect;

  private tenantId!: string;
  private userId!: string;
  private isFirstLoad = true;
  isPacImAccountType!: boolean;
  isDMEPOSAccountType !: boolean;

  lazyLoadStream = new Subject<LazyLoadEvent>();
  subscriptions = new Subscription();

  ngOnInit() {
    this.subscriptions.add(
        this.lazyLoadStream
          .pipe(distinctUntilChanged(isEqual))
          .subscribe(event => this.reloadUserProviders(event)),
      );
   }

   @Input() set user(value: string) {
    this.userId = value;
    this.loadUserProviders(this.defaultFilters);
  }

  @Input() set healthPlan(value: string) {
    this.tenantId = value;
    this.loadParentCompanies(this.tenantId);
    this.onChangeSearchType();
  }

  @Input() set accountTypeId(value: number) {
    const isAuthorizationTypeChange = PacImAccountTypesId.includes(this.businessTypeId) && !PacImAccountTypesId.includes(value)
      || !PacImAccountTypesId.includes(this.businessTypeId) && PacImAccountTypesId.includes(value);

    if (isAuthorizationTypeChange) {
      this.onChangeSearchType();
    }

    this.businessTypeId = value;
    this.isPacImAccountType = AccountTypes.PostAcuteFacility === value || AccountTypes.HospitalCMDischargePlanner === value;
    this.isDMEPOSAccountType = AccountTypes.DMEPOSProvider === value;
    this.loadParentCompanies(this.tenantId);
  }

  get accountTypeId(): number {
    return this.businessTypeId;
   }
  @Output() selectedProviders = new EventEmitter<boolean>();
  @Output() userProvidersChanged = new EventEmitter();

  get tenant(): string {
    return this.tenantId;
  }

  searchTypes = [
    {
      value: 'npi',
      label: 'NPI'
    },
    {
      value: 'company',
      label: 'Parent Company'
    }
  ];

  pacImProductNameParamValue = 'PAC';
  homeHealthProductNameParamValue = 'HH';
  dmeposProductNameParamValue = 'DMEPOS';
  pacFilter = { productName: { value: ['PAC', 'PAC Commercial'], matchMode: 'in' } };
  homeHealthFilter = { productName: { value: ['PAC', 'PAC Commercial', 'DMEPOS'], matchMode: 'notIn' } };
  dmeposFilter = { productName: { value: 'DMEPOS', matchMode: 'eq' } };
  unsortedFields = ['isActive'];
  searchType: string | null = null;
  searchTypeCurrent: string | null = '';
  npi!: string;
  companyName!: string;
  columns: TableColumn[];
  companies: SelectItem[] = [];
  loadingParentCompanies = false;
  productName!: string;

  // providers Table
  foundProviders: IProvider[] = [];
  searchedProviders: IProvider[] = [];
  foundProvidersTotalRecords = 0;
  selectAllStateProviders = false;

  isLoadingProviders = false;
  defaultSortFieldProviders = 'providerName';
  defaultSortOrderProviders = 1;
  amountOfRowsProviders = 10;
  readonly defaultFiltersProviders: LazyLoadEvent = {
    first: 0,
    rows: this.amountOfRowsProviders,
    sortField: this.defaultSortFieldProviders,
    sortOrder: this.defaultSortOrderProviders,
    filters: {},
    globalFilter: null
  };
  currentTableDefaultFilters: LazyLoadEvent = {
    filters: {}
  };
  providerTableFilters: LazyLoadEvent = {
    filters: {}
  };

  providerRelevantData!: IRelevantDataProviders;
  providerNameList: SelectItem[] = [];
  providerAddressList: SelectItem[] = [];
  npiList: SelectItem[] = [];
  taxIdList: SelectItem[] = [];
  healthPlanList: SelectItem[] = [];
  activeStateList: SelectItem[] = [];

  providerNameFilter: string[] = [];
  provideActiveFilter!: boolean | null;
  providerAddressListFilter: string[] = [];
  provideNpiFilter: string[] = [];
  provideTaxIdFilter: string[] = [];
  providerSelectedItems: IProvider[] = [];
  providerSelectedItemsCurrentState: Set<string | undefined> = new Set();

  // user Table
  userProviders: IProvider[] = [];
  userProvidersTotalRecords = 0;
  selectAllStateUserProviders = false;

  isLoading = false;
  isLoadingUsers = false;
  defaultSortField = 'providerName';
  defaultSortOrder = 1;
  amountOfRows = 10;
  readonly defaultFilters: LazyLoadEvent = {
    first: 0,
    rows: this.amountOfRows,
    sortField: this.defaultSortField,
    sortOrder: this.defaultSortOrder,
    filters: {},
    globalFilter: null
  };

  userTableFilters!: LazyLoadEvent;

  providerNameListProviders: SelectItem[] = [];
  providerAddressListProviders: SelectItem[] = [];
  npiListProviders: SelectItem[] = [];
  taxIdListProviders: SelectItem[] = [];
  healthPlanListProviders: SelectItem[] = [];
  activeStateListProviders: SelectItem[] = [];

  userProviderNameFilter: string[] = [];
  userActiveFilter!: boolean | null;
  userProviderAddressListFilter: string[] = [];
  userNpiFilter: string[] = [];
  userTaxIdFilter: string[] = [];
  userTenantNameListFilter: string[] = [];
  userSelectedItems: IProvider[] = [];
  private destroy$ = new Subject<void>();
  private businessTypeId!: number;

  constructor(
    private readonly userService: UsersService,
    private readonly oDataService: ODataService,
    private readonly messageService: MessageService,
    private readonly securityService: SecurityService,
    private readonly confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef
  ) {
    this.columns = [
      new TableColumn('Provider Name', 'providerName'),
      new TableColumn('Active', 'isActive'),
      new TableColumn('Provider Address', 'providerAddress'),
      new TableColumn('NPI', 'npi'),
      new TableColumn('Tax ID', 'taxId'),
      new TableColumn('Account', 'tenantName')
    ];
  }

  onChangeSearchType(): void {
    this.foundProviders = [];
    this.providerSelectedItems = [];
    this.npi = '';
    this.searchTypeCurrent = '';
    this.companyName = '';
    this.clearProvidersTable();
  }

  loadParentCompanies(tenantId: string): void {
    if (this.isDMEPOSAccountType){
      this.productName = this.dmeposProductNameParamValue;
    }
    else{
      this.productName = this.isPacImAccountType ? this.pacImProductNameParamValue : this.homeHealthProductNameParamValue;
    }   

    if (tenantId) {
      this.loadingParentCompanies = true;
      this.companies = [];
      this.subscriptions = this.userService
        .getParentCompanyNames(tenantId, this.productName)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.loadingParentCompanies = false;
          })
        )
        .subscribe(res => {
          if (res && res.length) {
            this.companies = res
              .filter(el => el)
              .map(el => ({
                label: el,
                value: el
              }));
          }
        });
    }
  }

  filterTableProviders(value: string[] | number[], criteria: string, matchMode: string): void {
    this.dataTableProviders.filter(value, criteria, matchMode);
  }

  filterTableUserProviders(value: string[] | number[], criteria: string, matchMode: string): void {
    this.dataTableUserProviders.filter(value, criteria, matchMode);
  }

  loadUserProviders(filter: LazyLoadEvent): void {
    if (!this.userId) {
      return;
    }
    
    this.userTableFilters = filter;
    this.isLoadingUsers = true;
    const queryParams = filter
      ? this.oDataService.buildQuery(filter)
      : this.oDataService.buildQuery(this.defaultFilters);

    forkJoin([
      this.getProvidersByUserId(this.userId, queryParams),
      this.getRelevantDataUserProviders(this.userId),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isLoadingUsers = false)
      )
      .subscribe(([providers, providerRelevantData]) => {
        if (!this.userService.hasProvidersDataByUserId(this.userId)) {
          this.userService.setProvidersDataByUserId(this.userId, providers);
        }
        if (!this.userService.hasRelevantDataByUserId(this.userId)) {
          this.userService.setRelevantDataByUserId(this.userId, providerRelevantData);
        }
        this.setupData(providers, providerRelevantData);
        this.isFirstLoad = false;
        this.cdr.detectChanges();
      });
  }

  reloadUserProviders(filter: LazyLoadEvent, needToReloadRelevantData = false): void {
    if (this.isFirstLoad) {
      return;
    }

    this.isLoadingUsers = true;
    const queryParams = filter
      ? this.oDataService.buildQuery(filter)
      : this.oDataService.buildQuery(this.defaultFilters);

      this.subscriptions = forkJoin([
      this.userService.getProvidersByUserId(this.userId, queryParams),
      needToReloadRelevantData ? this.userService.getProvidersByUserIdRelevantData(this.userId) :
        this.getRelevantDataUserProviders(this.userId),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isLoadingUsers = false)
      )
      .subscribe(([providers, providerRelevantData]) => {
        this.userService.setProvidersDataByUserId(this.userId, providers);
        this.userService.setRelevantDataByUserId(this.userId, providerRelevantData);
        this.setupData(providers, providerRelevantData);
      });
  }

  loadProviders(filters: LazyLoadEvent, searchParam?: string): void {
    if (this.tenantId) {
      const providerSelectedItems = Array.from(this.providerSelectedItemsCurrentState);

      this.isLoadingProviders = true;
      if (searchParam === 'npi') {
        this.providerTableFilters.filters = {
          ...filters.filters,
          ...{ npi: { value: this.npi, matchMode: 'eq' } },
          ...providerSelectedItems.length ? { providerAccountId: { value: providerSelectedItems, matchMode: 'notIn'}} : null
        };
      }

      if (searchParam === 'company') {
        this.providerTableFilters.filters = {
          ...filters.filters,
          ...{ parentCompany: { value: this.companyName, matchMode: 'eq' } },
          ...providerSelectedItems.length ? { providerAccountId: { value: providerSelectedItems, matchMode: 'notIn'}} : null
        };
      }

      this.providerTableFilters.filters = {
        ...this.providerTableFilters.filters,
        ...this.isPacImAccountType ? this.pacFilter : (this.isDMEPOSAccountType ? this.dmeposFilter : this.homeHealthFilter)
      };

      const queryParams = this.oDataService.buildQuery(this.providerTableFilters);
      this.subscriptions = this.userService
        .getProviderAccounts(this.tenantId, queryParams)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.isLoadingProviders = false)
        )
        .subscribe(res => {
          this.foundProviders = res.items;
          this.foundProvidersTotalRecords = res.count;
          this.setOptionsList(this.providerRelevantData);
        });
    }
  }  
 
   disableSearch(): boolean {
    return !(this.searchType && (this.companyName || this.npi));
  }

  onSearch(searchParam: string | null = ''): void {
    this.providerSelectedItemsCurrentState = new Set();
    this.isLoadingProviders = true;
    this.clearProvidersTable();

    this.providerTableFilters = cloneDeep(this.defaultFiltersProviders);
    this.searchTypeCurrent = searchParam;
    const filterRelevant = {
      filters: this.isPacImAccountType ? this.pacFilter : this.isDMEPOSAccountType ? this.dmeposFilter : this.homeHealthFilter
    };

    if (searchParam === 'npi') {
      filterRelevant.filters = {
        ...filterRelevant.filters,
        ...{npi: { value: this.npi, matchMode: 'eq' } }
      };
      this.providerTableFilters.filters = filterRelevant.filters;
    }

    if (searchParam === 'company') {
      filterRelevant.filters = {
        ...filterRelevant.filters,
        ...{ parentCompany: { value: this.companyName, matchMode: 'eq' } }
      };
      this.providerTableFilters.filters = filterRelevant.filters;
    }

    if (this.isPacImAccountType) {
      this.providerTableFilters.filters = {
        ...filterRelevant.filters,
      };
    }
    
    this.currentTableDefaultFilters = this.providerTableFilters;

    const filterRelevantParams = this.oDataService.buildShortQuery(filterRelevant);
    const queryParams = this.oDataService.buildQuery(this.providerTableFilters);
    if (this.tenantId) {
      const providerAccounts = this.userService.getProviderAccounts(this.tenantId, queryParams);
      const relevantData = this.userService.getProviderAccountsRelevantData(
        this.tenantId,
        filterRelevantParams
      );
      forkJoin([providerAccounts, relevantData])
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.isLoadingProviders = false)
        )
        .subscribe(res => {
          let providers;
          [providers, this.providerRelevantData] = res;
          this.foundProviders = providers.items;
          this.searchedProviders =this.foundProviders;
          this.foundProvidersTotalRecords = providers.count;
          this.setOptionsList(this.providerRelevantData);
        });
    }
  }

  onChangeSelectAllStateUserProviders(ev: { checked: boolean }): void {
    const { value } = this.dataTableUserProviders;
    if (ev.checked) {
      this.userSelectedItems = [...value.slice(0, value.length)];
    } else {
      this.userSelectedItems = [];
      this.selectedProviders.emit(false);
    }
  }

  onChangeSelectAllStateProviders(ev: { checked: boolean }): void {
    const { value } = this.dataTableProviders;
    if (ev.checked) {
      this.providerSelectedItems = [...value.slice(0, value.length)];
    } else {
      this.providerSelectedItems = [];
    }
  }

  onPageChangeUserProviders(): void {
    this.resetSelectionUsers();
  }

  onPageChangeProviders(): void {
    this.resetSelectionProviders();
  }

  handleLazyLoadUserProviders(event: LazyLoadEvent): void {
    this.resetSelectionUsers();
    this.userTableFilters = event;
    this.lazyLoadStream.next(cloneDeep(event));  
  }

  handleLazyLoadProviders(event: LazyLoadEvent): void {
    this.resetSelectionProviders();
    this.providerTableFilters = event;
    if (this.searchTypeCurrent) {
      this.loadProviders(event, this.searchTypeCurrent);
    }
  }

  resetSelectionUsers(): void {
    this.selectAllStateUserProviders = false;
    this.userSelectedItems = [];
    this.selectedProviders.emit(false);
  }

  resetSelectionProviders(): void {
    this.selectAllStateProviders = false;
  }

  clearAllFilters(): void {
    this.clearUserProvidersTable();
    this.clearProvidersTable(); 
    this.clearUserMsFilter();
    this.clearProviderMsFilter();

    this.reloadUserProviders(this.defaultFilters);
    this.reloadProviderTable(this.currentTableDefaultFilters);

  }

  reloadProviderTable(filter :LazyLoadEvent): void {
    this.currentTableDefaultFilters = filter;
    this.foundProviders = this.searchedProviders;
 }
  clearUserProvidersTable(): void {
    this.dataTableUserProviders.filters = {} ;
    this.userProviderNameFilter = [];
    this.userActiveFilter = null;
    this.userProviderAddressListFilter = [];
    this.userNpiFilter = [];
    this.userTaxIdFilter = [];
    this.userTenantNameListFilter = [];
    this.userSelectedItems = [];
    this.selectAllStateUserProviders = false;
    this.providerNameFilter = [];
  }

  clearProvidersTable(): void {
    this.providerNameFilter = [];
    this.provideActiveFilter = null;
    this.providerAddressListFilter = [];
    this.provideNpiFilter = [];
    this.provideTaxIdFilter = [];
    this.providerSelectedItems = [];
    this.selectAllStateProviders = false;
  }

  clearUserMsFilter(): void {
    this.userProvidersMs.filterValue = '';
    this.userAddressMs.filterValue = '';
    this.userNpiMs.filterValue = '';
    this.userTaxIdMs.filterValue = '';
    this.accountMs.filterValue = '';
  }

  clearProviderMsFilter(): void {
    this.providerListMs.filterValue = '';
    this.providerAddressMs.filterValue = '';
    this.providerNpiMs.filterValue = '';
    this.providerTaxIdMs.filterValue = '';
    this.userTableFilters.filters = {};
  }

  assignProviders(): void {
    const providerAccounts: Pick<IProvider, 'providerAccountId' | 'tenantId'>[] = [];
    this.providerSelectedItems.forEach(item =>
      providerAccounts.push(pick(item, ['providerAccountId', 'tenantId']))
    );
    const body = {
      providerAccounts,
      businessTypeId: this.businessTypeId,
      createdBy: ''
    };
    this.subscriptions = this.userService.assignProvidersToUser(this.userId, body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      if (res?.length) {
        this.messageService.add({
          severity: 'success',
          summary: 'Providers added',
          key: 'success',
          detail: `Providers you selected are successfully added.`,
          life: 5000
        });
        this.reloadUserProviders(this.userTableFilters, true);
        const providerSelectedItemIds = this.providerSelectedItems.map(
          item => item.providerAccountId
        );
        this.providerSelectedItemsCurrentState = new Set([...this.providerSelectedItemsCurrentState, ...providerSelectedItemIds]);

        this.providerTableFilters.filters = Object.assign(this.providerTableFilters.filters || {}, {
          providerAccountId: { matchMode: 'notIn', value: Array.from(this.providerSelectedItemsCurrentState) }
        });

        if (this.isPacImAccountType) {
          this.providerTableFilters.filters = {
            ...this.providerTableFilters.filters,
          };
        }

        this.providerTableFilters.filters = {
          ...this.providerTableFilters.filters,
          ...this.isPacImAccountType ? this.pacFilter : this.homeHealthFilter
        };

        this.loadProviders(this.providerTableFilters);
        this.providerSelectedItems = [];
        this.selectAllStateProviders = false;
        this.userProvidersChanged.emit();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          key: 'error',
          detail: `Providers you selected have already been added. Please choose other Providers.`,
          life: 5000
        });
      }
    });
  }

  removeSelectedItems(): void {
    const selectedItems: string[] = [];
    const assignedProviderAccountIds = Array.from(this.providerSelectedItemsCurrentState);

    this.userSelectedItems.forEach(item => {
      selectedItems.push(item.userTenantMapId);
      const indexOfEl = assignedProviderAccountIds.findIndex(el => el === item.providerAccountId);
      if (indexOfEl !== -1) {
        assignedProviderAccountIds.splice(indexOfEl, 1);
      }
    });

    this.providerSelectedItemsCurrentState = new Set(assignedProviderAccountIds);

    this.userService.removeProvidersFromUser(this.userId, selectedItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Providers removed',
          key: 'removed',
          detail: `Providers you selected are successfully removed.`,
          life: 5000
        });
        this.reloadUserProviders(this.userTableFilters, true);
        this.resetSelectionUsers();
        this.userProvidersChanged.emit();
      });
  }

  onRemove(): void {
    this.confirmationService.confirm({
      header: 'Remove Providers',
      message: 'Are you sure that you want to remove providers?',
      accept: () => {
        this.removeSelectedItems();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setOptionsList(relevantData: IRelevantDataProviders): void {
    if (relevantData) {
      this.providerNameListProviders = ArrayUtils.arrayToSelectItemArrayByItem(
        relevantData.providerName
      );
      this.providerAddressListProviders = ArrayUtils.arrayToSelectItemArrayByItem(
        relevantData.providerAddress
      );
      this.npiListProviders = ArrayUtils.arrayToSelectItemArrayByItem(relevantData.npi);
      this.taxIdListProviders = ArrayUtils.arrayToSelectItemArrayByItem(relevantData.taxId);
      this.activeStateListProviders = relevantData.isActive
        .map(item => (item ? { value: item, label: 'Active' } : { value: item, label: 'Inactive' }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  rowSelectProviders(): void {
    this.selectAllStateProviders =
      this.providerSelectedItems.length === this.foundProvidersTotalRecords ||
      this.providerSelectedItems.length === this.amountOfRows;
  }

  rowUnselectProviders(): void {
    this.selectAllStateProviders = false;
  }

  rowSelectUsers(): void {
    this.selectAllStateUserProviders =
      this.userSelectedItems.length === this.userProvidersTotalRecords ||
      this.userSelectedItems.length === this.amountOfRows;
  }

  rowUnselectUsers(): void {
    this.selectAllStateUserProviders = false;
  }

  disableUserProviderFilters(): boolean {
    return !this.userProviders.length && !this.healthPlanList.length;
  }

  get disabledClearAllButton(): boolean {
    const isSortAppliedProviders = !(
      this.dataTableProviders.sortOrder !== this.defaultSortOrderProviders ||
      this.dataTableProviders.sortField !== this.defaultSortFieldProviders
    );
    const isSortAppliedUsers = !(
      this.dataTableUserProviders.sortOrder !== this.defaultSortOrder ||
      this.dataTableUserProviders.sortField !== this.defaultSortField
    );

    return isSortAppliedUsers && isSortAppliedProviders
      && this.isFilterEmpty(this.userTableFilters)
      && this.isFilterEqual(this.providerTableFilters, this.currentTableDefaultFilters);
  }

  isFilterEmpty(tableFilters: LazyLoadEvent): boolean {
    return isEmpty(tableFilters?.filters);
  }

  isFilterEqual(tableFilters: LazyLoadEvent, defaultFilters: LazyLoadEvent): boolean {
    const filters = {...tableFilters?.filters};

    if (!defaultFilters.filters?.providerAccountId && filters?.providerAccountId) {
      delete filters.providerAccountId;
    }

    return isEqual(filters, defaultFilters?.filters);
  }

  disableAssignButton(): boolean {
    return (this.securityService.hasAccess(['ExternalUser.Write'])) ?
      !this.providerSelectedItems.length :
      true;
  }

  private getRelevantDataUserProviders(userId: string): Observable<IRelevantDataUserProviders> {
    return this.userService.hasRelevantDataByUserId(userId) ?
      this.userService.getRelevantDataByUserId(userId) :
      this.userService.getProvidersByUserIdRelevantData(userId);
  }

  private getProvidersByUserId(
    userId: string, queryParams: string
  ): Observable<IProviderSearchResult<IProvider>> {
    return this.userService.hasProvidersDataByUserId(userId) ?
      this.userService.getProvidersDataByUserId(userId) :
      this.userService.getProvidersByUserId(userId, queryParams);
  }

  private setupData(
    providers: IProviderSearchResult<IProvider>, providerRelevantData: IRelevantDataUserProviders
  ): void {
    this.userProviders = providers.items;
    this.userProviders.length ?
      this.selectedProviders.emit(true) :
      this.selectedProviders.emit(false);

    this.userProvidersTotalRecords = providers.count;

    const relevantData = providerRelevantData;
    this.providerNameList = ArrayUtils.arrayToSelectItemArrayByItem(
      relevantData.providerNameList
    );
    this.providerAddressList = ArrayUtils.arrayToSelectItemArrayByItem(
      relevantData.providerAddressList
    );
    this.npiList = ArrayUtils.arrayToSelectItemArrayByItem(relevantData.npiList);
    this.taxIdList = ArrayUtils.arrayToSelectItemArrayByItem(relevantData.taxIdList);
    this.healthPlanList = ArrayUtils.arrayToSelectItemArrayByItem(
      relevantData.healthPlanList
    );
    this.activeStateList = relevantData.statusList
      .map(item =>
        item ? { value: item, label: 'Active' } : { value: item, label: 'Inactive' }
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}
