import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IInternalUserItem, IProviderSearchResult, IInternalUserQuery } from '@core/model';
import { UsersService } from '@core/services';
import { TableColumn } from '@mynexus/mynexus-ui-lib';
import { isEmpty } from 'lodash-es';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-internal-users-list',
  templateUrl: './internal-users-list.component.html',
  styleUrls: ['./internal-users-list.component.scss']
})
export class InternalUsersListComponent implements OnInit, OnDestroy {
  @ViewChild('dt', { static: true }) dataTable!: Table;

  readonly amountOfRows = 10;
  readonly defaultSortField = 'displayName';
  readonly defaultSortOrder = 1;
  readonly defaultSortOrderOnLoad = 1;

  cols: TableColumn[];
  isLoading = true;
  totalRecords = 0;
  users: IInternalUserItem[] = [];

  email = '';
  displayName = '';
  isActive?: boolean;
  activesList: SelectItem[] = [];
  internalUserFilter: IInternalUserQuery = { displayName: '', email: '', isActive: undefined, page: 0, sort: '', top: this.amountOfRows };
  private subs = new SubSink();

  constructor(
    private usersService: UsersService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.cols = [
      new TableColumn('', '', '5.3rem'),
      new TableColumn('Business Email', 'email'),
      new TableColumn('Display Name', 'displayName'),
      new TableColumn('Active', 'isApproved', '20rem')
    ];
  }

  ngOnInit(): void {
    this.activesList = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' }
    ];

    this.isLoading = true;
  }

  resetFilters(): void {
    this.dataTable.filters = {};
    this.dataTable.clear();
    this.dataTable.sort({ field: this.defaultSortField });
    this.email = '';
    this.displayName = '';
    this.isActive = undefined;
  }


  getUsers(event: LazyLoadEvent): void {
    this.isLoading = true;
    this.buildAdditionalFilters(event);
    this.users = [];

    var queryParams:Record<any, any> = {
      isExternal: false,
      displayName: this.internalUserFilter.displayName,
      email: this.internalUserFilter.email,
      page: this.internalUserFilter.page,
      sort: this.internalUserFilter.sort,
      top: this.internalUserFilter.top,
      take: event.rows,
      skip: event.first,
      orderByDescending: event.sortOrder && event.sortOrder > 0 ? false : true,
      orderBy: event.sortField
    };
    if(this.internalUserFilter.isActive !== undefined){
      queryParams.isApproved = this.internalUserFilter.isActive;
    }

    var queryString = new URLSearchParams(queryParams).toString();
    if(queryParams.orderBy != null){
      this.subs.sink = this.usersService
      .getInternalUsers(queryString)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: IProviderSearchResult<IInternalUserItem>) => {
          if (res) {
            this.users = res.items;
            this.totalRecords = res.count;
            this.isLoading = false;
          }
        },
        error: error => {
          this.messageService.add({
            severity: 'error',
            detail: error.message,
            life: 5000
          });
        }
      });
    }
  }

  editUser(userId: string): void {
    this.router.navigate(['internalusers', userId, 'details']);
  }

  private isFilterEmpty(): boolean {
    return isEmpty(this.dataTable.filters);
  }

  isClearAllEnabled(): boolean {
    const isSortApplied = !(
      this.dataTable.sortOrder !== this.defaultSortOrderOnLoad ||
      this.dataTable.sortField !== this.defaultSortField
    );

    return isSortApplied && this.isFilterEmpty();
  }

  // tslint:disable-next-line: no-any
  applyFilterTableValue(value: any, criteria: string, matchMode: string): void {
    this.dataTable.filter(value, criteria, matchMode);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // adds additional filter parameters based on lazyLoad event details
  private buildAdditionalFilters(event: LazyLoadEvent){
    // Set page using the event first data
    // first is equivalent to skip, but we do not currently support skip, only page
    // So we have to calculate the page number.
    this.internalUserFilter.page = ((event.first ?? 0) / this.amountOfRows) + 1;

    // reset filters, and readd as needed
    this.internalUserFilter.displayName = '';
    this.internalUserFilter.email = '';
    this.internalUserFilter.isActive = undefined;

    // Filters below will need to match names as defined in HTML, and used in applyFilterTableValue()
    if (event.filters) {
      if (event.filters['displayName']) {
        this.internalUserFilter.displayName = event.filters['displayName'].value;
      }
      if (event.filters['email']) {
        this.internalUserFilter.email = event.filters['email'].value;
      }
      if (event.filters['isApproved']) {
        this.internalUserFilter.isActive = event.filters['isApproved'].value;
      }
    }
  }

}
