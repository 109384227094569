import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { PickListModule } from 'primeng/picklist';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';

const primeNgModules = [
  AutoCompleteModule,
  ButtonModule,
  MenubarModule,
  DropdownModule,
  MenuModule,
  PanelModule,
  CalendarModule,
  CardModule,
  CheckboxModule,
  InputMaskModule,
  TableModule,
  DropdownModule,
  MultiSelectModule,
  InputTextModule,
  MessageModule,
  PanelModule,
  ToastModule,
  ConfirmDialogModule,
  RadioButtonModule,
  DialogModule,
  ProgressSpinnerModule,
  PickListModule,
  TabViewModule,
  AccordionModule
];

@NgModule({
  imports: [...primeNgModules],
  exports: [...primeNgModules]
})
export class PrimeNgModule {}
