import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IInternalUser } from '@core/model';
import { UsersService } from '@core/services';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-user-profile-widget',
  templateUrl: './user-profile-widget.component.html',
  styleUrls: ['./user-profile-widget.component.scss']
})
export class UserProfileWidgetComponent implements OnInit {
  userId = '';
  user!: IInternalUser;
  subs = new SubSink();
  isLoading = false;
  form!: FormGroup;
  // tslint:disable-next-line: no-any
  initialFormValue!: any;
  isFormChanged = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe(params => {
      this.userId = params.uid;
    });
    this.getUserById(this.userId);
  }

  getUserById(userId: string): void {
    this.isLoading = true;
    this.subs.sink = this.userService
      .getInternalUserById(userId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(user => {
        this.user = user;
        this.user.isActive = this.user.isApproved;
        this.user.userId = userId;
        this.createForm(this.user);
      });
  }

  goBack(): void {
    this.router.navigate(['users'], { queryParams: { index: 1 } });
  }

  onSave(): void {
    const data = { ...this.form.value };
    const internalUser = {
      isApproved: this.user.isActive,
      firstName: data.givenName,
      lastName: data.surname,
      displayName: `${data.givenName} ${data.surname}`,
      credentials: data.credentials
    } as IInternalUser;
    
    this.subs.sink = this.userService.saveInternalUserById(this.userId, internalUser).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        key: 'saved',
        detail: `All changes were saved successfully`,
        life: 5000
      });
      this.getUserById(this.userId);
      this.isFormChanged = false;
    });
  }

  onCancel(): void {
    if (this.form) {
      this.form.get('givenName')?.setValue(this.user.firstName);
      this.form.get('surname')?.setValue(this.user.lastName);
      this.form.get('credentials')?.setValue(this.user.credentials);
      this.form.get('otherMails')?.setValue(this.user.email);
    }
  }

  createForm(user: IInternalUser): void {
    this.form = new FormGroup({
      givenName: new FormControl(user.firstName, Validators.required),
      surname: new FormControl(user.lastName, Validators.required),
      credentials: new FormControl(user.credentials),
      otherMails: new FormControl({ disabled: true, value: user.email })
    });
    this.initialFormValue = Object.assign({}, this.form.value);
    this.form.valueChanges.subscribe(() => this.handleFormChange());
  }

  handleFormChange(): void {
    this.isFormChanged = this.formChanged();
  }

  formChanged(): boolean {
    return (
      Object.keys(this.initialFormValue).filter(
        key => this.initialFormValue[key] !== this.form.value[key]
      ).length > 0
    );
  }

  get canSaveUser(): boolean {
    return !(this.isFormChanged && this.form.valid);
  }

}
