import { Injectable } from '@angular/core';
import { IMfeService } from '@mynexus/mfe-core';

@Injectable({
  providedIn: 'root'
})
export class MfeRegistryService {
  private mfeServices: Record<string, IMfeService> = {};

  getMfeService(appName: string): IMfeService {
    return this.mfeServices[appName];
  }

  registerMfeService(appName: string, service: IMfeService): void {
    this.mfeServices[appName] = service;
  }
}
