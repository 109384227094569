import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { TableColumn } from '@mynexus/mynexus-ui-lib';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { IGroupDTO, ISearchGroupDTOResults } from '@core/model';
import { GraphService } from '@core/services';

@Component({
  selector: 'app-internal-user-groups',
  templateUrl: './internal-user-groups.component.html',
  styleUrls: ['./internal-user-groups.component.scss']
})
export class InternalUserGroupsComponent implements OnDestroy {
  @ViewChild('dt', { static: true }) dataTable!: Table;

  @Input() set userId(value: string) {
    this.getUserGroups(value);
  }

  readonly defaultSortField = 'displayName';
  readonly defaultSortOrder = 1;

  isLoading = true;
  groups: IGroupDTO[] = [];
  totalRecords = 0;
  amountOfRows = 10;

  cols: TableColumn[];

  private subs = new SubSink();

  constructor(private graphService: GraphService) {
    this.cols = [
      new TableColumn('User Group', 'displayName', '20rem')
    ];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private getUserGroups(userId: string): void {
    this.isLoading = true;

    this.groups = [];
    this.subs.sink = this.graphService
      .getUserGroups(userId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res: ISearchGroupDTOResults) => {
          if (res) {
            this.groups = res.items;
            this.totalRecords = this.groups.length;
          }
        }
      );

  }

}
