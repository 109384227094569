<div class="p-3">
  <app-user-profile-widget>
  </app-user-profile-widget>
</div>
<div class="p-3">
  <p-accordion>
    <p-accordionTab header="User Account Information" [selected]="true">
      <app-assign-usergroups [user]="userId"></app-assign-usergroups>
    </p-accordionTab>
  </p-accordion>
</div>
