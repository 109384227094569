import { Inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AUTH_CONFIG } from '@core/config/config.service';
import { IAuthConfig } from '@env/IEnvironment';
import { IAuthenticationService, IUserClaims } from '@mynexus/mfe-core';

@Injectable()
export class AuthenticationService implements IAuthenticationService {

  constructor(
    private msalService: MsalService,
    @Inject(AUTH_CONFIG) private authConfig: IAuthConfig,
  ) { }

  getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.msalService.acquireTokenSilent({
        scopes: this.authConfig.angularConfig.protectedResourceMap[0][1],
        account: this.user,
      })
        .subscribe({
          next: ({ accessToken }) => resolve(accessToken),
          error: reject
        })
    });
  }

  get userClaims(): IUserClaims {
    return this.user?.idTokenClaims
      ? (this.user.idTokenClaims as IUserClaims)
      : {};
  }

  get userEmail(): string | null {
    const claims = this.userClaims;

    if (claims?.emails) {
      const emails = claims.emails;

      if (emails.length > 0) {
        return emails[0];
      }
    }

    return null;
  }

  get userGroups(): string[] {
    return this.userClaims?.groups || [];
  }

  get token(): string | undefined {
    return this.user?.idToken;
  }

  get user(): AccountInfo {
    return this.msalService.instance.getAllAccounts()[0];
  }

  get userName(): string {
    // tslint:disable-next-line: no-non-null-assertion
    return this.user ? this.user.name! : '';
  }

  checkUserRole(roleName: string): boolean {
    return (this.user.idTokenClaims as any)['groups'].includes(roleName);
  }

  logout(): void {
    this.msalService.logout();
  }
}

