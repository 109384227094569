import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { CentralizedDepartmentService } from './centralized-department.service';
import { UsersService } from '@core/services';
import { IExternalUser } from '@core/model';
import { Observable, Subject, catchError, of, switchMap } from 'rxjs';
import { ParentCompany } from './models/centralized-department.model';
import { AccountTypes } from '@core/constants';
import { SubSink } from 'subsink';
import { ConflictErrorMessage, GeneralErrorMessage } from '@core/constants/error-messages.const';

@Component({
  selector: 'app-centralized-department-toggle',
  templateUrl: './centralized-department-toggle.component.html',
  styleUrls: ['./centralized-department-toggle.component.scss']
})
export class CentralizedDepartmentToggleComponent implements OnInit {
  @Input() userId!: string;
  @Input() providersListChanges$!: Subject<void>;
  @Input() accountType$!: Subject<number>;
  @Input() currentBusinessType!: string;

  public shouldShowCentralizedDepartmentToggle: boolean = true;
  public isLoading: boolean = true;
  public isToggleDisabled: boolean = false;
  public companiesOptions: SelectItem[] = [];

  public isCentralizedDepartmentControl = new FormControl(false);
  public companyControl = new FormControl<string | null>(null);

  private currentCompany: string | null = null;

  private readonly cidAccountTypes = [
    AccountTypes.SDoHProviderAdmin,
    AccountTypes.ReferralProgramProviderAdmin,
  ];

  private subs = new SubSink();

  @ViewChild('companyDropdown', { static: false }) companyDropdown?: Dropdown;

  constructor(
    private readonly centralizedDepartmentService: CentralizedDepartmentService,
    private readonly usersService: UsersService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit() {
    this.subs.sink = this.providersListChanges$
      .pipe(switchMap(() => this.getCompaniesList()))
      .subscribe((companies: ParentCompany[]) => this.setCompanyData(companies));

    this.subs.sink = this.accountType$.subscribe((accountType: AccountTypes) => {
      this.onAccountTypeChange(accountType);
    });
    this.providersListChanges$.next();
  }

  toggleSwitchChanged() {
    if (!this.isCentralizedDepartmentControl.value) {
      this.unsetCompany();
      this.resetCompanyControl();
      return;
    }
    this.focusCompanyDropdown();
  }

  onCompanyControlBlur() {
    this.companyControl.setValidators([Validators.required]);
    this.companyControl.markAsDirty();
    this.companyControl.updateValueAndValidity();
  }

  setCompany() {
    if (!this.companyControl.value) {
      return;
    }
    this.centralizedDepartmentService
      .setCompany(this.userId, this.companyControl.value)
      .pipe(catchError(error => this.showErrorMessage(error.status)))
      .subscribe(res => {
        if (res) this.currentCompany = this.companyControl.value;
      });
  }

  private unsetCompany() {
    if (!this.currentCompany) {
      return;
    }
    this.centralizedDepartmentService
      .unsetCompany(this.userId, this.currentCompany)
      .pipe(catchError(error => this.showErrorMessage(error.status)))
      .subscribe(res => {
        if (res) this.currentCompany = null;
      });
  }

  private onAccountTypeChange(accountType: AccountTypes) {
    this.isCentralizedDepartmentControl.setValue(false);
    this.toggleSwitchChanged();
    this.shouldShowCentralizedDepartmentToggle = this.cidAccountTypes.includes(accountType);
  }

  private getCompaniesList(): Observable<ParentCompany[]> {
    this.isLoading = true;
    return this.usersService.getUserById(this.userId).pipe(
      switchMap((user: IExternalUser) => {
        this.setUserData(user);
        return this.centralizedDepartmentService.getCompaniesList(this.userId);
      })
    );
  }

  private setUserData(user: IExternalUser): void {
    const { centralizedDepartment, accountTypeId } = user;
    this.currentCompany = centralizedDepartment;
    this.shouldShowCentralizedDepartmentToggle = this.cidAccountTypes.includes(accountTypeId);
  }

  private setCompanyData(companies: ParentCompany[] = []) {
    this.isToggleDisabled = companies.length === 0;
    this.companiesOptions = companies.map((company: ParentCompany) => ({
      label: company.parentCompany,
      value: company.parentCompany
    }));
    this.isCentralizedDepartmentControl.setValue(!!this.currentCompany);
    this.companyControl.setValue(this.currentCompany);
    this.isLoading = false;
  }

  private focusCompanyDropdown() {
    setTimeout(() => {
      if (!this.companyDropdown?.focused) {
        this.companyDropdown?.focus();
        this.companyDropdown?.show();
      }
    });
  }

  private resetCompanyControl() {
    this.companyControl.setValue(null);
    this.companyControl.setErrors(null);
    this.companyControl.markAsUntouched();
    this.companyControl.markAsPristine();
  }

  private showErrorMessage(code: number): Observable<boolean> {
    const isConflict = code === 409;
    this.messageService.add({
      severity: isConflict ? 'warn' : 'error',
      summary: isConflict ? 'Warning' : 'Error',
      detail: isConflict ? ConflictErrorMessage : GeneralErrorMessage,
      life: 5000
    });
    return of(false);
  }
}
