<p-table
  #dt
  class="table-parameters table-simple-paginator m-3"
  [alwaysShowPaginator]="false"
  [value]="providers"
  [columns]="cols"
  [paginator]="true"
  [rows]="amountOfRows"
  [totalRecords]="totalRecords"
  [lazy]="true"
  [filterDelay]="1000"
  (onLazyLoad)="loadData($event)"
  [loading]="isLoading"
>
  <!-- <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width" />
    </colgroup>
  </ng-template> -->
  <ng-template pTemplate="header" let-columns>
    <!-- <tr class="header-sorting"> -->
    <tr>
      <th
          *ngFor="let col of columns"
          [pSortableColumn]="unsortedFields.includes(col.field) ? '' : col.field"
          [pSortableColumnDisabled]="unsortedFields.includes(col.field)"
        >
        <!-- <span [innerText]='col.header'></span> -->
        {{col.header}}
        <p-sortIcon
          *ngIf="!unsortedFields.includes(col.field) && col.header !==''"
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        >
        </p-sortIcon>
      </th>
    </tr>
    <!-- <tr class="header-filter"> -->
    <tr class="header-filter">
      <th>
        <p-multiSelect
          styleClass="p-column-filter"
          [options]="providerNameList"
          (onChange)="filterTable($event.value, 'providerName', 'in')"
          [maxSelectedLabels]="0"
          [(ngModel)]="providerName"
          defaultLabel="Filter"
          appendTo="body"
          [disabled]="disableFilters()"
        ></p-multiSelect>
      </th>
      <th>
        <p-dropdown
          placeholder="Filter"
          styleClass="p-column-filter"
          [options]="activeStateList"
          [showClear]="true"
          (onChange)="filterTable($event.value, 'isActive', 'eq')"
          [(ngModel)]="userActiveFilter"
          appendTo="body"
          [disabled]="disableFilters()"
        ></p-dropdown>
      </th>
      <th>
        <p-multiSelect
          styleClass="p-column-filter"
          [options]="providerAddressList"
          (onChange)="filterTable($event.value, 'providerAddress', 'in')"
          [maxSelectedLabels]="0"
          [(ngModel)]="providerAddress"
          defaultLabel="Filter"
          appendTo="body"
          [disabled]="disableFilters()"
        ></p-multiSelect>
      </th>
      <th>
        <p-multiSelect
          styleClass="p-column-filter"
          [options]="npiList"
          (onChange)="filterTable($event.value, 'npi', 'in')"
          [maxSelectedLabels]="0"
          [(ngModel)]="npi"
          defaultLabel="Filter"
          appendTo="body"
          [disabled]="disableFilters()"
        ></p-multiSelect>
      </th>
      <th>
        <p-multiSelect
          styleClass="p-column-filter"
          [options]="taxIdList"
          (onChange)="filterTable($event.value, 'taxId', 'in')"
          [maxSelectedLabels]="0"
          [(ngModel)]="taxId"
          defaultLabel="Filter"
          appendTo="body"
          [disabled]="disableFilters()"
        ></p-multiSelect>
      </th>
      <th>
        <p-multiSelect
          styleClass="p-column-filter"
          [options]="healthPlanList"
          (onChange)="filterTable($event.value, 'tenantName', 'in')"
          [maxSelectedLabels]="0"
          [(ngModel)]="tenantName"
          defaultLabel="Filter"
          appendTo="body"
          [showHeader]="false"
          [disabled]="disableFilters()"
        ></p-multiSelect>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <p>{{ isLoading ? 'Loading... please wait' : 'No record found' }}</p>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-provider>
    <tr>
      <td>{{ provider.providerName }}</td>
      <td>{{ provider.isActive ? 'Active' : 'Inactive' }}</td>
      <td>{{ provider.providerAddress }}</td>
      <td>{{ provider.npi }}</td>
      <td>{{ provider.taxId }}</td>
      <td>{{ provider.tenantName }}</td>
    </tr>
  </ng-template>
</p-table>
