<p-accordion>
  <p-accordionTab
    [(selected)]="!collapsed"
    (selectedChange)="expandPanel(!$event)"
    class="btn-table"
  >
  <ng-template pTemplate="header">
    <div class="full-width flex justify-content-between align-items-center">
      <span>
        Audit Log
      </span>
      <button
        *ngIf="!collapsed"
        pButton
        label="Refresh"
        aria-label="Refresh table"
        class="p-button-warning p-button-rounded button-icon p-mr-2"
        icon="pi pi-refresh"
        (click)="loadData($event)"
      ></button>
    </div>
  </ng-template>
    <!-- <ng-template pTemplate="icons">

    </ng-template> -->

    <p-table
      aria-label="Audit Log table"
      class="table-parameters table-paginator"
      [alwaysShowPaginator]="false"
      [value]="data"
      [columns]="columns"
      [paginator]="true"
      [sortOrder]="defaultSortOrder"
      [sortField]="defaultSortField"
      [totalRecords]="totalRecords"
      [rows]="10"
      [loading]="isLoading"
      responsiveLayout="scroll"
    >
      <!-- <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width]="col.width" />
        </colgroup>
      </ng-template> -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <!-- <div class="table-header-sort"> -->
              {{ col.header }}
              <p-sortIcon
                [field]="col.field"
                ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order"
              >
              </p-sortIcon>
            <!-- </div> -->
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">
            <p class="my-2">No record found</p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.signinDateTime | utcToLocal }}</td>
          <td>{{ item.appDisplayName }}</td>
          <td>{{ item.loginStatus }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-accordionTab>
</p-accordion>
<!-- <p-panel
  header="Audit Log"
  [toggleable]="true"
  [(collapsed)]="collapsed"
  (onAfterToggle)="expandPanel($event)"
>
  <ng-template pTemplate="icons">
    <button
      *ngIf="!collapsed"
      pButton
      label="Refresh"
      aria-label="Refresh table"
      class="p-button-warning p-button-rounded p-button-sm p-mr-2"
      icon="pi pi-refresh"
      (click)="loadData()"
    ></button>
  </ng-template>


</p-panel> -->
