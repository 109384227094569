<div class="p-3">
  <app-user-profile-widget
    (accountTypeId)="accountTypeIdExist($event)"
    [accountTypeIdNew]="accountTypeIdNew"
    [userAccountInformationChanged]="userAccountInformationChanged"
  >
  </app-user-profile-widget>
</div>
<div class="px-3">
  <app-account-information-widget
    [userId]="userId"
    [accountTypeId]="accountTypeId"
    (accountTypeIdNew)="onChangeAccountType($event)"
    (userAccountInformationChanged)="onUserAccountInformationChanged()"
  >
  </app-account-information-widget>
</div>
<div class="p-3">
  <app-audit-log-widget [userId]="userId"></app-audit-log-widget>
</div>
