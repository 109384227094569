export class UrlConstants {
  public static readonly users = 'Users';
  public static readonly usersRelevantData = 'Users/RelevantData';
  public static readonly providers = 'Providers';
  public static readonly userProviders = 'UserProviders';
  public static readonly providerManagement = 'ProviderManagement';
  public static readonly getUserAndTenantsForRegistration = 'User/GetUserAndTenantsForRegistration';
  public static readonly internalUsers = 'Users/internal-users';
  public static readonly internalUserSave = 'Graph/UpdateInternalUser';
  public static readonly getInternalUserInfo = 'Graph/GetUser';
  public static readonly getBusinessTypes = "Utilities/BusinessTypes";
}

export class UserGroupRoles {
  public static readonly PORTALADMIN = 'PortalAdmin';
  public static readonly EXTERNALUSERMANAGEMENT = 'ExternalUserManagement';
  public static readonly INTERNALUSERMANAGEMENT = 'InternalUserManagement';
}

export class GraphRoutes {
  public static readonly GetUsers = 'Graph/GetUsers';
  public static readonly GetUser = 'Graph/GetUser';
  public static readonly UpdateUser = 'Graph/UpdateUser';
  public static readonly EnrolledDevices = 'Graph/GetUserEnrolledDevices';
  public static readonly DeleteEnrolledDevices = 'Graph/DeleteUserEnrolledDevices';
}

export class UserManagementRoutes {
  public static readonly GetUsers = 'UserManagement/GetUsers';
  public static readonly GetExternalUser = 'UserManagement/GetExternalUser';
  public static readonly UpdateExternalUser = 'UserManagement/UpdateExternalUser';
}