import { Inject, Injectable } from '@angular/core';
import { API_PLATFORM_URL } from '@core/config/config.service';
import { HandleError, HttpErrorHandler } from '@core/http/http-error-handler.service';
import { HttpUtility } from '@core/http/http-utility';
import { IGroupDTO, ISearchGroupDTOResults } from '@core/model';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  public handleError: HandleError;
  private userGroupsByUserId = new Map<string, Array<IGroupDTO>>();
  private allUserGroups$ = new BehaviorSubject<IGroupDTO[] | null>(null);

  constructor(
    private httpUtility: HttpUtility,
    private httpErrorHandler: HttpErrorHandler,
    @Inject(API_PLATFORM_URL) private readonly platformApiBaseUrl: string
  ) {
    this.handleError = this.httpErrorHandler.createHandleError('GraphService');
  }

  public addUserToGroups(userId: string, userGroups: IGroupDTO[]): Observable<string | null> {
    const groupIds = userGroups.map(group => group.groupId);
    return this.httpUtility.post<string>(`${this.platformApiBaseUrl}/Graph/AddGroupsToUser/${userId}`, groupIds)
      .pipe(
        catchError(this.handleError('addUserToGroups', null))
      );
  }

  public fetchAllUserGroups(): void {
    if (!this.allUserGroups$.value) {
      this.httpUtility.get<ISearchGroupDTOResults>(`${this.platformApiBaseUrl}/Graph/GetGroups`)
        .pipe(
          take(1),
          catchError(this.handleError('fetchAllUserGroups', [] as Array<ISearchGroupDTOResults>))
        ).subscribe((groups: ISearchGroupDTOResults) => {
        this.allUserGroups$.next(groups.items);
      });
    }
  }

  public getAllUserGroups(): Observable<Array<IGroupDTO> | null> {
    return this.allUserGroups$.asObservable();
  }

  public hasUserGroupsByUserId(userId: string): boolean {
    return this.userGroupsByUserId.has(userId);
  }

  public getUserGroupsByUserId(userId: string): Array<IGroupDTO> {
    return this.userGroupsByUserId.get(userId)!;
  }

  public setUserGroupsByUserId(userId: string, userGroups: Array<IGroupDTO>): void {
    this.userGroupsByUserId.set(userId, userGroups);
  }

  public getUserGroups(userId: string): Observable<ISearchGroupDTOResults> {
    return this.httpUtility.get<ISearchGroupDTOResults>(`${this.platformApiBaseUrl}/Graph/GetUserGroups/${userId}`)
      .pipe(
        catchError(this.handleError('getUserGroups', { items: [] as Array<IGroupDTO>, count: null}))
      );
  }

  public removeUserFromGroups(userId: string, userGroups: IGroupDTO[]): Observable<string | null> {
    const groupIds = userGroups.map(group => group.groupId);
    return this.httpUtility.post<string>(`${this.platformApiBaseUrl}/Graph/RemoveGroupsFromUser/${userId}`, groupIds)
      .pipe(
        catchError(this.handleError('removeUserFromGroups', null))
      );
  }
}
