import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CONFIG_ENV } from '@core/config/config.service';
import { IEnvironmentVariables } from '@env/IEnvironment';

@Component({
  selector: 'app-login',
  template: ''
})
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    private msalService: MsalService,
    @Inject(CONFIG_ENV) private config: IEnvironmentVariables) {
  }

  ngOnInit() {
    this.msalService.handleRedirectObservable().subscribe({
      next: (authResult) => {
        if (authResult && (authResult?.idTokenClaims as any)['tfp'] === this.config.b2cPolicies.names.forgotPassword) {
          this.msalService.logout();
          return;
        }
        this.router.navigate(['']);
      },
      error: (error) => {
        this.handleLoginError(error);
      },
    });
  }

  private handleLoginError(error: { errorMessage: string[] }): void {
    if (!error) {
      return;
    }

    const USER_FORGOT_PASSWORD_CODE = 'AADB2C90118';
    const USER_CANCEL_FLOW_CODE = 'AADB2C90091';

    if (error.errorMessage.includes(USER_FORGOT_PASSWORD_CODE)) {
      this.msalService.loginRedirect({
        authority: `${this.config.b2cPolicies.authorityBaseUrl}/${this.config.b2cPolicies.names.forgotPassword}`,
        scopes: this.config.authConfig.angularConfig.consentScopes,
      });
    }

    if (error.errorMessage.includes(USER_CANCEL_FLOW_CODE)) {
      this.msalService.loginRedirect({
        authority: `${this.config.b2cPolicies.authorityBaseUrl}/${this.config.b2cPolicies.names.signUpSignIn}`,
        scopes: this.config.authConfig.angularConfig.consentScopes,
      });
    }
  }
}
