import { NgModule } from '@angular/core';
import { ChangeUserStatusDirective } from './change-user-status.directive';
import { ChangeUserStatusV2Directive } from './change-user-status-v2.directive';

const DIRECTIVES = [
    ChangeUserStatusDirective,
    ChangeUserStatusV2Directive
];

@NgModule({
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES]
})
export class DirectiveModule {
}
