<p-accordion class="mb-3">
  <p-accordionTab header="User Account Information" [selected]="true">
    <div class="flex justify-content-center" *ngIf="isLoading">
      <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
    </div>
    <div *ngIf="!isLoading">
      <div class="col-fixed field health-plan-dd-width">
        <label>Health Plan:</label>
        <p-dropdown
          class="ml-3"
          [options]="applicableHealthPlans"
          [(ngModel)]="healthPlan"
          (onChange)="onChangeHealthPlan($event.value)"
          placeholder="Select Item"
          appendTo="body"
        >
        </p-dropdown>
      </div>
      <app-account-switch
        [accountTypes]="applicableAccountTypes"
        [accountId]="accountTypeId"
        [hasSelectedProviders]="hasSelectedProviders"
        [userId]="userId"
        [tenantId]="healthPlan?.tenantId"
        (accountTypeIdNew)="onChangeAccountType($event)"
      ></app-account-switch>
    </div>

    <app-centralized-department-toggle
      [userId]="userId"
      [providersListChanges$]="providersListChanges$"
      [accountType$]="accountType$"
      [currentBusinessType]="businessType?.businessType"
    ></app-centralized-department-toggle>

    <app-assign-providers-to-user
       *ngIf="showAssignProvidersWidget"
      [healthPlan]="healthPlan?.tenantId"
      [user]="userId"
      [accountTypeId]="accountTypeId"
      (selectedProviders)="onSelectProvider($event)"
      (userProvidersChanged) ="onUserProvidersChanged()"
    ></app-assign-providers-to-user>
  </p-accordionTab>
</p-accordion>
