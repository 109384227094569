import { Component, Input, OnDestroy } from '@angular/core';
import { IUserLog } from '@core/model';
import { UsersService } from '@core/services';
import { TableColumn } from '@mynexus/mynexus-ui-lib';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-audit-log-widget',
  templateUrl: './audit-log-widget.component.html',
  styleUrls: ['./audit-log-widget.component.scss']
})
export class AuditLogWidgetComponent implements OnDestroy {
  @Input() userId!: string;
  columns: TableColumn[];
  collapsed = true;
  isLoading = false;
  totalRecords = 0;
  data: IUserLog[] = [];
  defaultSortOrder = -1;
  defaultSortField = 'signinDateTime';
  amountOfRows = 10;

  private subs: Subscription = new Subscription();

  constructor(private usersService: UsersService) {
    this.columns = [
      new TableColumn('Event Date/Time', 'signinDateTime', '14rem'),
      new TableColumn('Event Type', 'appDisplayName', '18rem'),
      new TableColumn('Login Status', 'loginStatus', '10rem')
    ];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  expandPanel(collapsed: boolean): void {
    this.collapsed = collapsed;

    if (collapsed === false && !this.data?.length) {
      this.loadData();
    }
  }

  loadData(event?: Event): void {
    event?.stopPropagation();

    if (!this.isLoading) {
      this.collapsed = false;
      this.isLoading = true;

      this.subs = this.usersService.getUserLog(this.userId)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe((result: IUserLog[] | undefined) => {
          if (result) {
            this.data = result;
            this.totalRecords = result.length;
          }
        });
    }
  }
}
