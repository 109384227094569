import { SecurityService } from '@mynexus/mynexus-ui-lib';
import { Component, Injector, OnInit } from '@angular/core';
import { SharedServiceComponent } from '@shared/components/shared-service/shared-service.component';
import { createCustomElement } from '@angular/elements';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'user-management';

  constructor(
    private injector: Injector,
    private securityService: SecurityService,
  ) { 
    customElements.define('mnc-shared-services', createCustomElement(SharedServiceComponent, { injector: this.injector }));
  }

  ngOnInit(): void {
    this.securityService.setAllowEmptyPermission(false);
  }
}
