import { Component } from '@angular/core';
import { AuthenticationService } from '@core/authentication/services';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  userName = this.authenticationService.userName;

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  logout(): void {
    this.authenticationService.logout();
  }
}
