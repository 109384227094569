import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ODataService } from '@mynexus/mynexus-ui-lib';
import { HttpErrorHandler, HttpUtility } from '@core/http';
import { TenantsService, UsersService } from '@core/services';
import { AuthenticationModule } from '@core/authentication/authentication.module';
import { MessageService } from 'primeng/api';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once-guard';
import { UtilitiesService } from './services/utilities.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    AuthenticationModule,
  ],
  providers: [
    { provide: 'windowRef', useValue: window },
    { provide: 'documentRef', useValue: document },
    ODataService,
    UsersService,
    TenantsService,
    MessageService,
    UtilitiesService,
    HttpErrorHandler,
    HttpUtility
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
