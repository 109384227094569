import { PrimeNgModule } from '@shared/prime-ng/prime-ng.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountInformationWidgetComponent } from './account-information-widget/account-information-widget.component';
import { AccountSwitchComponent } from './account-switch/account-switch.component';
import { AssignProvidersToUserComponent } from './assign-providers-to-user/assign-providers-to-user.component';
import { AuditLogWidgetComponent } from './audit-log-widget/audit-log-widget.component';
import { UserDetailsComponent } from './user-details.component';
import { UserProfileWidgetComponent } from './user-profile-widget/user-profile-widget.component';
import { CentralizedDepartmentToggleComponent } from './centralized-department-toggle/centralized-department-toggle.component';

@NgModule({
  declarations: [
    AccountInformationWidgetComponent,
    AuditLogWidgetComponent,
    UserDetailsComponent,
    UserProfileWidgetComponent,
    AssignProvidersToUserComponent,
    AccountSwitchComponent,
    CentralizedDepartmentToggleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    PrimeNgModule
  ],
  exports: [UserDetailsComponent]
})
export class UsersDetailsModule {}
