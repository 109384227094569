import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/authentication/services';
import { UserGroupRoles } from '@core/constants';
import { UsersService } from '@core/services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService, private usersService: UsersService) { }

  internalUserBack = false;
  internalUserAccess = false;
  externalUserAccess = false;
  
  ngOnInit(): void {

    this.internalUserAccess = this.authenticationService.checkUserRole(UserGroupRoles.INTERNALUSERMANAGEMENT);
    this.externalUserAccess = this.authenticationService.checkUserRole(UserGroupRoles.EXTERNALUSERMANAGEMENT);

    if (this.route.snapshot.queryParams.index) {
      this.internalUserBack = true;
    }

  }

}
