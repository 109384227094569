
<p-tabView *ngIf="internalUserAccess || externalUserAccess">
  <p-tabPanel header="External Users" [selected]="!internalUserBack" *ngIf="externalUserAccess">
    <ng-template pTemplate="content">
      <app-external-users-list></app-external-users-list>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Internal Users" [selected]="internalUserBack" *ngIf="internalUserAccess">
    <ng-template pTemplate="content">
      <app-internal-users-list></app-internal-users-list>
    </ng-template>
  </p-tabPanel>
</p-tabView>

<p-panel *ngIf="!internalUserAccess && !externalUserAccess"
         header="Unauthorized"
         [showHeader]="true"
         [toggleable]="false"
         styleClass="unauthorized-message">
  <p>
    You do not have access to view this page. Please contact an administrator to review your access.
  </p>
</p-panel>

<p-toast styleClass="toast-text-wrap" position="bottom-left"></p-toast>

<p-confirmDialog header="Confirmation"
                 appendTo="body"
                 [rejectIcon]="''"
                 [acceptIcon]="''"
                 [rejectButtonStyleClass]="'p-button-secondary'">
</p-confirmDialog>
