import { Directive, Input, HostListener } from '@angular/core';
import { IExternalUserItem, IInternalUserItem } from '@core/model';
import { UsersService } from '@core/services';
import { ConfirmationService } from 'primeng/api';

@Directive({
  selector: '[appChangeUserStatus]'
})
export class ChangeUserStatusDirective {
  @Input('appChangeUserStatus') user!: IExternalUserItem | IInternalUserItem;

  constructor(private usersService: UsersService,  private confirmationService: ConfirmationService, ) { }

  @HostListener('click', ['$event']) onClick(): void {
    if (this.user) {
      const isActive = this.user.isActive;
      const message = isActive ? 'Are you sure that you want to deactivate the user?' : 'Are you sure that you want to activate the user?';
      this.confirmationService.confirm({
        message,
        accept: () => {
          this.usersService.updateUserActiveStatus(this.user.userId, !isActive).subscribe(() => {
            this.user.isActive = !isActive;
          });
        }
      });
    }
  }
}
