import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AccessDeniedComponent } from '@core/authentication/components/access-denied/access-denied.component';
import { AccessByPermissionGuard, AccessPageGuard } from '@mynexus/mynexus-ui-lib';
import { LoginComponent } from '@core/authentication/components/login/login.component';
import { MainComponent } from '@feature/main/main.component';
import { UserDetailsComponent } from '@feature/user-details/user-details.component';
import { UsersComponent } from '@feature/users/users.component';
import { InternalUserDetailsComponent } from '@feature/internal-user-details/internal-user-details.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' }
    },
  {
    path: '',
    component: MainComponent,
    canActivate: mapToCanActivate([AccessByPermissionGuard]),
    children: [
      {
        path: '',
        redirectTo: '/users',
        pathMatch: 'full',
      },
      {
        path: 'users',
        component: UsersComponent,
        data: { title: 'users', permissions: ['ExternalUser.Read'] },
        canActivate: mapToCanActivate([MsalGuard, AccessPageGuard]),
      },
      {
        path: 'users/:uid/details',
        component: UserDetailsComponent,
        data: { title: 'user-details', permissions: ['ExternalUser.Read'] },
        canActivate: mapToCanActivate([MsalGuard, AccessPageGuard]),
      },
      {
        path: 'internalusers/:uid/details',
        component: InternalUserDetailsComponent,
        data: { title: 'user-details', permissions: ['ExternalUser.Read'] },
        canActivate: mapToCanActivate([MsalGuard, AccessPageGuard]),
      },
      {
        path: 'page401',
        component: AccessDeniedComponent,
        data: { title: 'Access Denied' },
        canActivate: mapToCanActivate([MsalGuard]),
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/users'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
