import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';


/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  constructor() {}

  /** Create curried handleError function that already knows the service name */
  createHandleError = <T>(serviceName = '') => {
    return (operation = 'operation', result = {} as T) =>
      this.handleError<T>(serviceName, operation, result);
  };

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

  // eslint-disable-next-line unused-imports/no-unused-vars
  handleError = <T>(serviceName = '', operation = 'operation', result = {} as T) => {
    // tslint:disable-next-line: no-any
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(error);
    };
  };
}
