  // tslint:disable: no-any
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpUtility {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private httpClient: HttpClient
  ) {
  }

  get<T>(url: string, options?: any): Observable<any> {
    return this.httpClient
      .get<T>(url, options);
  }

  post<T>(url: string, body?: any): Observable<T> {
    return this.httpClient
      .post<T>(url, JSON.stringify(body), this.httpOptions);
  }

  put<T>(url: string, body?: any): Observable<T> {
    return this.httpClient
      .put<T>(url, JSON.stringify(body), this.httpOptions);
  }

  delete<T>(url: string, options?: any): Observable<any> {
    return this.httpClient
      .delete<T>( url, options);
  }
}
