import { PipeModule } from './pipe/pipe.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectiveModule } from '@shared/directives/directive.module';
import { LibPipeModule, LibDirectiveModule, SecurityModule } from '@mynexus/mynexus-ui-lib';
import { PrimeNgModule } from './prime-ng/prime-ng.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    DirectiveModule,
    LibPipeModule,
    PipeModule,
    LibDirectiveModule,
    SecurityModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    DirectiveModule,
    PipeModule,
    LibPipeModule,
    LibDirectiveModule,
    SecurityModule
  ]
})
export class SharedModule { }
