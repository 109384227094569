<p-table #dt
         class="table-parameters table-simple-paginator m-3"
         [alwaysShowPaginator]="false"
         [value]="groups"
         [columns]="cols"
         [paginator]="true"
         [rows]="amountOfRows"
         [totalRecords]="totalRecords"
         [filterDelay]="1000"
         [loading]="isLoading"
         responsiveLayout="scroll"
         [defaultSortOrder]="defaultSortOrder"
         [sortField]="defaultSortField">
  <!-- <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width" />
    </colgroup>
  </ng-template> -->
  <ng-template pTemplate="header" let-columns>
    <!-- <tr class="header-sorting"> -->
    <tr>
      <th *ngFor="let col of columns"
          [pSortableColumn]="col.field">
        <!-- <span [innerText]='col.header'></span> -->
        {{col.header}}
        <p-sortIcon *ngIf="col.field && col.header !==''"
                    [field]="col.field"
                    ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
    <!-- </tr> -->
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <p>{{ isLoading ? 'Loading... please wait' : 'No record found' }}</p>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-group>
    <tr>
      <td>{{ group.displayName }}</td>
    </tr>
  </ng-template>
</p-table>
