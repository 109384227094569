import { Injectable, InjectionToken } from '@angular/core';
import { IAuthConfig, IEnvironmentVariables } from '@env/IEnvironment';

export const CONFIG_ENV = new InjectionToken<IEnvironmentVariables>('CONFIG_ENV');
export const CONFIG_FILE = new InjectionToken<string>('CONFIG_FILE');
export const API_BASE_REGISTRY_URL = new InjectionToken<string>('API_BASE_REGISTRY_URL');
export const API_URL = new InjectionToken<string>('API_URL');
export const API_PLATFORM_URL = new InjectionToken<string>('API_PLATFORM_URL');
export const AUTH_CONFIG = new InjectionToken<IAuthConfig>('AUTH_CONFIG');
export const BASE_PERMISSIONS_URL = new InjectionToken<IAuthConfig>('BASE_PERMISSIONS_URL');

export function ConfigFactory(configService: ConfigService, file: string, property: string): any {
  const configData = configService.loadConfig(file)

  return configData ? (property ? (configData.environment as any)[property] : (configData.environment || {})) : {};
}

export function ConfigFactoryPermissionsUrl(apiBaseUrl: string): string {
  return apiBaseUrl.replace('/api', '');
}

export function ConfigFactoryBasePermissionsUrl(apiBaseUrl: string): string {
  return `${apiBaseUrl}/`;
}

export function ConfigFactoryApiUrl(configService: ConfigService, file: string): string {
  const configData = configService.loadConfig(file);

  return `${configData.environment?.apiUrl}`;
}

export function ConfigFactoryPlatformApiUrl(configService: ConfigService, file: string): string {
  const configData = configService.loadConfig(file);

  return `${configData.environment?.platformApiUrl}`;
}

export function ConfigFactoryAuthConfig(configService: ConfigService, file: string): IAuthConfig {
  const configData = configService.loadConfig(file);

  return configData.environment.authConfig;
}

export function ConfigFactoryApiRegistryUrl(configService: ConfigService, file: string): string {
  const configData = configService.loadConfig(file);

  return `${configData.environment?.registryUrl}`;
}

@Injectable()
export class ConfigService {
  public config: any;

  loadConfig(filePath: string): {environment: IEnvironmentVariables} {
    if (!this.config) {
      const data = this.loadData(filePath);
      this.config = JSON.parse(data);
    }
    if (!this.config?.environment || !this.config?.b2cPolicies) {
      throw new Error('Configuration data not found!');
    }
    return this.config;
  }

  loadData(filePath: string): string {
    const mimeType = 'application/json';

    const http = new XMLHttpRequest();
    http.open('GET', filePath, false);
    if (mimeType != null) {
      if (http.overrideMimeType) {
        http.overrideMimeType(mimeType);
      }
    }
    http.send();
    http.onerror = () => {
      console.error('Error load config data!');
    };
    if (http.status === 200) {
      return http.responseText;
    } else {
      return '{}';
    }
  }
}
