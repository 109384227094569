<div>

  <div class="grid">
    <div class="col">
      <p-table #dtp
               aria-label="Group table"
               class="table-parameters table-simple-paginator"
               [value]="foundGroups"
               [columns]="groupColumns"
               [(selection)]="groupSelectedItems"
               [paginator]="true"
               [sortOrder]="defaultSortOrderGroups"
               [loading]="isLoadingGroups$ | async"
               [defaultSortOrder]="defaultSortOrderGroups"
               [sortField]="defaultSortFieldGroups"
               [totalRecords]="foundGroupsTotalRecords"
               [rows]="amountOfRows"
               [scrollable]="true"
               (onRowSelect)="rowSelectGroups()"
               (onRowUnselect)="rowUnselectGroups()"
               [globalFilterFields]="['displayName']"
               resetTablePageOnSort>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="table-icon-width">
              <p-checkbox [binary]="true"
                          [disabled]="foundGroups && foundGroups.length === 0"
                          [(ngModel)]="selectAllStateGroups"
                          (onChange)="onChangeSelectAllStateGroups($event)">
              </p-checkbox>
            </th>
            <th *ngFor="let col of columns"
                [ngStyle]="{ display: col.display }"
                [pSortableColumn]="col.field">
              <span [innerText]='col.header'></span>
              <p-sortIcon *ngIf="col.field && col.header !==''"
                          [field]="col.field"
                          ariaLabel="Activate to sort"
                          ariaLabelDesc="Activate to sort in descending order"
                          ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr class="header-filter">
            <th class="table-icon-width"></th>
            <th>
              <input pInputText type="text" (input)="applyFilterGroupTable($event,'displayName', 'contains')" placeholder="Search by Group" class="ui-column-filter">
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              <h7 class="p-my-2">No record found</h7>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="table-icon-width">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td>{{ item.displayName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-fixed text-center pt-6">
      <div class="col-12 px-0">
        <button type="button"
                class="p-ripple p-button p-component p-button-icon-only"
                (click)="assignGroups()"
                [disabled]="disableAssignButton()">
          <span class="p-button-icon pi pi-angle-right" aria-hidden="true"></span>
        </button>
      </div>
      <div class="col-12 px-0">
        <button type="button"
                class="p-ripple p-button p-component p-button-icon-only"
                (click)="onRemove()"
                [disabled]="!userSelectedItems.length"
                label="Remove Selected">
          <span class="p-button-icon pi pi-angle-left" aria-hidden="true"></span>
        </button>
      </div>
    </div>
    <div class="col">
      <p-table #dtu
               aria-label="Group users table"
               class="table-parameters table-simple-paginator"
               [value]="userGroups"
               [columns]="userGroupColumns"
               [(selection)]="userSelectedItems"
               [sortOrder]="defaultSortOrder"
               [sortField]="defaultSortField"
               [loading]="isLoadingUsers"
               [defaultSortOrder]="defaultSortOrder"
               [totalRecords]="userGroupsTotalRecords"
               [paginator]="true"
               [rows]="amountOfRows"
               [scrollable]="true"
               (onRowSelect)="rowSelectUsers()"
               (onRowUnselect)="rowUnselectUsers()"
               [globalFilterFields]="['displayName']"
               resetTablePageOnSort>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="table-icon-width">
              <p-checkbox [binary]="true"
                          [disabled]="userGroups && userGroups.length === 0"
                          [(ngModel)]="selectAllStateUserGroups"
                          (onChange)="onChangeSelectAllStateUserGroups($event)">
              </p-checkbox>
            </th>
            <th *ngFor="let col of columns"
                [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon *ngIf="col.field && col.header !==''"
                          [field]="col.field"
                          ariaLabel="Activate to sort"
                          ariaLabelDesc="Activate to sort in descending order"
                          ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>
          </tr>
          <tr class="header-filter">
            <th class="table-icon-width"></th>
            <th>
              <input pInputText type="text" (input)="applyFilterUserGroupTable($event,'displayName', 'contains')" placeholder="Search by Group" class="ui-column-filter">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="2">
              <h7 class="p-my-2">No record found</h7>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="table-icon-width">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td>{{ item.displayName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog header="Confirmation"
                 icon="pi pi-exclamation-triangle"
                 appendTo="body"
                 key="confirmRemoved"></p-confirmDialog>
<p-toast key="removed" position="bottom-left"></p-toast>
<p-toast key="error" position="bottom-left"></p-toast>
<p-toast key="success" position="bottom-left"></p-toast>
