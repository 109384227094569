import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IElementRegistryService } from './element-registry.interface';
import { MfeRegistryService } from './mfe-registry.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { API_BASE_REGISTRY_URL } from '@core/config/config.service';
import { IMicroApplication, IWidget } from './microapplication.interface';

@Injectable({
  providedIn: 'root',
})
export class ElementRegistryService implements IElementRegistryService {
  static loadedScripts: { [url: string]: Promise<any> } = {};
  scriptsHaveBeenLoaded = new Set<string>();
  private knownElements: Record<string, Promise<IMicroApplication>> = {};
  private appNames?: Array<string>;

  constructor(
    private http: HttpClient,
    private mfeRegistryService: MfeRegistryService,
    @Inject(API_BASE_REGISTRY_URL) private registryUrl: string,
  ) {
  }

  /** Ensures scripts and styles for the given custom-element are loaded. */
  prepare(name: string): Promise<IMicroApplication> {
    name = name.toLowerCase();

    if (!this.knownElements[name]) {
      this.knownElements[name] = lastValueFrom(this.http.get<IMicroApplication>(`${this.registryUrl}/elements/${name}.json`))
        .then(microApp => {
          if (!microApp)
            throw new Error(`The element ${name} json file was not found`);

          this.addToKnownElements(microApp, name);
          this.loadStyles(microApp);

          return this.loadScripts(microApp).then(() => microApp);
        });
    }

    return new Promise<IMicroApplication>(resolve => {
      this.knownElements[name].then(microApp => {
        if (!customElements.get(name)) {
          this.mfeRegistryService.getMfeService(microApp.appElementName)?.loadComponent(name).then(() => resolve(microApp));
        } else {
          resolve(microApp);
        }
      });
    });
  }

  public loadAllAppNames(): Promise<Array<string>> {
    if (this.appNames)
      return lastValueFrom(of(this.appNames));

    return lastValueFrom(this.http.get<string[]>(`${this.registryUrl}/applications/element-names`).pipe(
      map(appNames => {
        return this.appNames = appNames.map(i => i.toLowerCase())
      })
    ));
  }

  public loadAllApps(): Promise<any> {
    return this.http
      .get<string[]>(`${this.registryUrl}/applications/element-names`)
      .toPromise()
      .then((elements) => Promise.all(elements!.map((i) => this.prepare(i))))
      .catch((e) => console.log('ERROR...:', e));
  }


  baseUrlFor(applicationName: string): string {
    const script = document.querySelector(
      'script[data-for="' + applicationName + '"]'
    ) as any;
    return (script && script.src.replace(/\/[^\/]+$/, '/')) || null;
  }

  addToKnownElements(info: IMicroApplication, name: string) {
    if (info.appElementName !== name) {
      this.knownElements[info.appElementName] = this.knownElements[name];
    }
    info.widgets!.forEach((w: IWidget) => {
      if (w.name !== name) {
        this.knownElements[w.name] = this.knownElements[name];
      }
    });
  }

  private loadStyles(info: IMicroApplication) {
    if (info.styleUrls === undefined) {
      return;
    }

    info.styleUrls.forEach((url) => {
      if (document.head.querySelector(`link[href="${url}"]`) == null) {
        const style = document.createElement('link');
        style.setAttribute('rel', 'stylesheet');
        style.href = url;
        document.head.appendChild(style);
      }
    });
  }

  private loadScripts(info: IMicroApplication): Promise<void> {
    const promises = info.scriptUrls.map((url) =>
      this.loadScript(url, info.appElementName)
    );

    return Promise.all(promises).then(
      () => {
        console.log(`Loaded all scripts for ${info.appElementName}`);
        this.scriptsHaveBeenLoaded.add(info.appElementName);
      },
      () => Promise.reject(`Cannot load scripts for ${info.appElementName}`)
    );
  }

  private loadScript(url: string, name: string): Promise<void> {
    return !!ElementRegistryService.loadedScripts[url]
      ? ElementRegistryService.loadedScripts[url]
      : (ElementRegistryService.loadedScripts[url] = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('type', 'module');
        script.setAttribute('data-for', name);
        script.onload = resolve;
        script.onerror = reject;
        script.src = url;
        document.body.appendChild(script);
      }
      ));
  }
}
