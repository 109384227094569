import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { IProvider, IRelevantDataUserProviders } from '@core/model';
import { UsersService } from '@core/services';
import { ArrayUtils, ODataService, TableColumn } from '@mynexus/mynexus-ui-lib';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-external-user-provides',
  templateUrl: './external-user-provides.component.html',
  styleUrls: ['./external-user-provides.component.scss']
})
export class ExternalUserProvidesComponent implements OnDestroy {
  @ViewChild('dt', { static: true }) dataTable!: Table;
  @Input() userId = '';

  readonly defaultSortField = 'providerName';
  readonly defaultSortOrder = 1;

  isLoading = true;
  providers: IProvider[] = [];
  totalRecords = 0;
  amountOfRows = 10;

  cols: TableColumn[];
  healthPlanList: SelectItem[] = [];
  providerNameList: SelectItem[] = [];
  providerAddressList: SelectItem[] = [];
  npiList: SelectItem[] = [];
  taxIdList: SelectItem[] = [];
  activeStateList: SelectItem[] = [];

  providerName?: string;
  providerAddress?: string;
  npi?: string;
  taxId?: string;
  tenantName?: string;
  userActiveFilter!: boolean;
  unsortedFields = ['isActive'];

  private subs: Subscription = new Subscription();

  constructor(private usersService: UsersService, private oDataService: ODataService) {
    this.cols = [
      new TableColumn('Provider Name', 'providerName', '20rem'),
      new TableColumn('Active', 'isActive', '9rem'),
      new TableColumn('Provider Address', 'providerAddress', '26rem'),
      new TableColumn('NPI', 'npi', '9rem'),
      new TableColumn('Tax ID', 'taxId', '9rem'),
      new TableColumn('Account', 'tenantName', '10rem')
    ];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  filterTable(value: string[] | number[], criteria: string, matchMode: string): void {
    this.dataTable.filter(value, criteria, matchMode);
  }

  loadData(filters: LazyLoadEvent): void {
    if (!filters.sortField) {
      filters.sortField = this.defaultSortField;
    }
    this.dataTable.sortField = filters.sortField;

    const queryParams = this.oDataService.buildQuery(filters);
    this.getProviders(queryParams);
  }

  private getProviders(queryParams: string): void {
    this.isLoading = true;
    this.subs = forkJoin([
      this.usersService.getProvidersByUserId(this.userId, queryParams),
      this.getRelevantDataUserProviders(this.userId)
    ])
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(([providers, relevantData]) => {
        if (!this.usersService.hasRelevantDataByUserId(this.userId)) {
          this.usersService.setRelevantDataByUserId(this.userId, relevantData);
        }

        this.providers = providers.items;
        this.totalRecords = providers.count;

        this.providerNameList = ArrayUtils.arrayToSelectItemArrayByItem(
          relevantData.providerNameList
        );
        this.providerAddressList = ArrayUtils.arrayToSelectItemArrayByItem(
          relevantData.providerAddressList
        );
        this.npiList = ArrayUtils.arrayToSelectItemArrayByItem(relevantData.npiList);
        this.taxIdList = ArrayUtils.arrayToSelectItemArrayByItem(relevantData.taxIdList);
        this.healthPlanList = ArrayUtils.arrayToSelectItemArrayByItem(
          relevantData.healthPlanList
        );
        this.activeStateList = relevantData.statusList
          .map(item =>
            item ? { value: item, label: 'Active' } : { value: item, label: 'Inactive' }
          )
          .sort((a, b) => a.label.localeCompare(b.label));
      });
  }

  disableFilters(): boolean {
    return !this.providers.length && !this.healthPlanList?.length;
  }

  private getRelevantDataUserProviders(userId: string): Observable<IRelevantDataUserProviders> {
    return this.usersService.hasRelevantDataByUserId(userId) ?
      this.usersService.getRelevantDataByUserId(userId) :
      this.usersService.getProvidersByUserIdRelevantData(userId);
  }
}
