<div class="flex justify-content-between align-items-center pb-3">
  <h5 class="my-2">Manage Internal Users</h5>
  <button
    *ngIf="dataTable"
    pButton
    type="button"
    class="p-button-warning p-button-rounded"
    label="Clear Filter Selections"
    [disabled]="isClearAllEnabled()"
    (click)="resetFilters()"
  ></button>
</div>
<p-table
  #dt
  dataKey="email"
  class="table-paginator"
  [value]="users"
  [lazy]="true"
  (onLazyLoad)="getUsers($event)"
  [columns]="cols"
  [paginator]="true"
  [rows]="amountOfRows"
  [totalRecords]="totalRecords"
  [filterDelay]="1000"
  [loading]="isLoading"
  [sortOrder]="defaultSortOrderOnLoad"
  [defaultSortOrder]="defaultSortOrder"
  [sortField]="defaultSortField"
  [scrollable]="true"
  [showCurrentPageReport]="true"
  responsiveLayout="scroll"
  resetTablePageOnSort
  [alwaysShowPaginator]="false"
  [rowsPerPageOptions]="[5, 10, 20]"
  currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="action-column"></th>
      <th pSortableColumn="email">
        <div class="table-header-sort">
          Business Email
          <p-sortIcon
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
            ariaLabel="Activate to sort"
            field="email"
          >
          </p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="displayName">
        <div class="table-header-sort">
          Display Name
          <p-sortIcon
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
            ariaLabel="Activate to sort"
            field="displayName"
          >
          </p-sortIcon>
        </div>
      </th>
      <th>Active</th>
    </tr>

    <tr class="header-filter">
      <th class="action-column"></th>
      <th>
        <input
          pInputText
          type="text"
          [(ngModel)]="email"
          appendTo="body"
          (blur)="applyFilterTableValue(email, 'email', 'contains')"
          (keyup)="applyFilterTableValue(email, 'email', 'contains')"
          placeholder="Search by email"
          class="ui-column-filter"
        />
      </th>
      <th>
        <input
          pInputText
          type="text"
          [(ngModel)]="displayName"
          appendTo="body"
          (blur)="applyFilterTableValue(displayName, 'displayName', 'contains')"
          (keyup)="applyFilterTableValue(displayName, 'displayName', 'contains')"
          placeholder="Search by name"
          class="ui-column-filter"
        />
      </th>
      <th>
        <p-dropdown
          placeholder="Filter"
          styleClass="p-column-filter"
          [options]="activesList"
          [showClear]="true"
          (onChange)="applyFilterTableValue($event.value, 'isApproved', 'eq')"
          [(ngModel)]="isActive"
          appendTo="body"
        ></p-dropdown>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <p>{{ isLoading ? 'Loading... please wait' : 'No record found' }}</p>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-user let-columns="columns" let-expanded="expanded">
    <tr>
      <td class="action-column">
        <button
          type="button"
          pButton
          pRipple
          class="p-button-text p-py-0 p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          [pRowToggler]="user"
        ></button>

        <button
          pButton
          pRipple
          type="button"
          class="p-button-text"
          icon="pi pi-pencil"
          (click)="editUser(user.userId)"
          title="Edit User"
        ></button>

        <button
          pButton
          type="button"
          [icon]="user.isApproved ? 'pi pi-lock' : 'pi pi-unlock'"
          class="p-button-text p-p-1 p-button-plain"
          title="Activate or In-Activate An User. On Activation Password Setup Email Will Be Sent."
          [accessEnableSubmit]="['ExternalUser.Write']"
          [appChangeUserStatusV2]="user"
        ></button>
      </td>
      <td>
        <div class="text-truncate expand-group">
          {{ user.email }}
        </div>
      </td>
      <td>
        {{ user.displayName }}
      </td>
      <td>
        {{ user.isApproved ? 'Active' : 'Inactive' }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-user>
    <tr>
      <td colspan="4">
        <app-internal-user-groups [userId]="user.userId"></app-internal-user-groups>
      </td>
    </tr>
  </ng-template>
</p-table>
