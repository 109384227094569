import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@core/config/config.service';
import { UrlConstants } from '@core/constants';
import { HttpUtility } from '@core/http';
import { UserTenants, AccountTypeUpdate } from '@core/model';
import { Observable } from 'rxjs';

@Injectable()
export class TenantsService {
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private httpUtility: HttpUtility,
    @Inject(API_URL) private readonly apiBaseUrl: string
  ) {}

  getTenants(): Observable<UserTenants> {
    const url = `${this.apiBaseUrl}/${UrlConstants.getUserAndTenantsForRegistration}`;
    return this.httpUtility.get<UserTenants>(url);
  }

  updateAccountType(userId: string, data: AccountTypeUpdate): Observable<null> {
    const url = `${this.apiBaseUrl}/${UrlConstants.users}/${userId}/accountType`;
    return this.httpUtility.put<null>(url, data);
  }
}
