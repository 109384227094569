import { GlobalErrorHandler } from '@core/global-error-handler';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FeatureToggleModule, SecurityModule, UtcToLocalPipe, LibComponentsModule } from '@mynexus/mynexus-ui-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { MainComponent } from '@feature/main/main.component';
import { UsersDetailsModule } from '@feature/user-details/users-details.module';
import { UsersModule } from '@feature/users/users.module';
import { SharedModule } from '@shared/shared.module';
import { InternalUsersDetailsModule } from '@feature/internal-user-details/internal-user-details.module';
import { APP_PRODUCT } from '@core/config/app-products';
import { ConfirmationService } from 'primeng/api';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { API_BASE_REGISTRY_URL, API_PLATFORM_URL, API_URL, AUTH_CONFIG, CONFIG_ENV, CONFIG_FILE, ConfigFactory, ConfigFactoryApiRegistryUrl, ConfigFactoryApiUrl, ConfigFactoryAuthConfig, ConfigFactoryPlatformApiUrl, ConfigService } from '@core/config/config.service';
import { MfeCoreModule } from '@mynexus/mfe-core';

@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [
    BrowserModule,
    UsersModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    UsersDetailsModule,
    LibComponentsModule,
    InternalUsersDetailsModule,
    FeatureToggleModule.forRoot(API_URL),
    SecurityModule.forRoot([APP_PRODUCT], 'page401', 'userManagement'),
    MfeCoreModule
  ],
  providers: [
    ConfigService,
    { provide: CONFIG_FILE, useValue: `../config.json?v=${generateHashKey()}` },
    UtcToLocalPipe,
    ConfirmationService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: API_URL,
      useFactory: ConfigFactoryApiUrl,
      deps: [ConfigService, CONFIG_FILE],
    },
    {
      provide: API_PLATFORM_URL,
      useFactory: ConfigFactoryPlatformApiUrl,
      deps: [ConfigService, CONFIG_FILE],
    },
    {
      provide: API_BASE_REGISTRY_URL,
      useFactory: ConfigFactoryApiRegistryUrl,
      deps: [ConfigService, CONFIG_FILE]
    },
    {
      provide: AUTH_CONFIG,
      useFactory: ConfigFactoryAuthConfig,
      deps: [ConfigService, CONFIG_FILE]
    },
    {
      provide: CONFIG_ENV,
      useFactory: ConfigFactory,
      deps: [ConfigService, CONFIG_FILE]
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


function generateHashKey(): string {
  return Math.random().toString(16)
}