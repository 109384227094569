import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-internal-user-details',
  templateUrl: './internal-user-details.component.html',
  styleUrls: ['./internal-user-details.component.scss']
})
export class InternalUserDetailsComponent implements OnInit, OnDestroy {
  userId!: string;
  private subs = new SubSink();

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe(params => {
      this.userId = params.uid;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
