import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ExternalUsersListComponent } from './external-users-list/external-users-list.component';
import { UsersComponent } from './users.component';
import { ExternalUserProvidesComponent } from './external-user-provides/external-user-provides.component';
import { LibDirectiveModule } from '@mynexus/mynexus-ui-lib';
import { InternalUsersListComponent } from './internal-users-list/internal-users-list.component';
import { InternalUserGroupsComponent } from './internal-user-groups/internal-user-groups.component';


@NgModule({
  declarations: [
    UsersComponent,
    ExternalUsersListComponent,
    ExternalUserProvidesComponent,
    InternalUsersListComponent,
    InternalUserGroupsComponent
  ],
  imports: [
    LibDirectiveModule,
    CommonModule,
    SharedModule
  ],
  exports: [
    UsersComponent,
    LibDirectiveModule
  ],
  providers: []
})
export class UsersModule {}
