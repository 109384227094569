import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@core/config/config.service';
import { UrlConstants } from '@core/constants';
import { HttpUtility } from '@core/http';
import { BusinessType } from '@mynexus/mynexus-ui-lib';
import { Observable } from 'rxjs';

@Injectable()
export class UtilitiesService {
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(
    private httpUtility: HttpUtility,
    @Inject(API_URL) private readonly apiBaseUrl: string
  ) { }

  getBusinessTypes(): Observable<BusinessType[]> {
    const url = `${this.apiBaseUrl}/${UrlConstants.getBusinessTypes}`;
    return this.httpUtility.get<BusinessType[]>(url);
  }
}
