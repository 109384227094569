import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { SecurityService, TableColumn } from '@mynexus/mynexus-ui-lib';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, of, switchMap, Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { IGroupDTO, ISearchGroupDTOResults } from '@core/model/graph';
import { GraphService } from '@core/services/graph.service';

@Component({
  selector: 'app-assign-usergroups',
  templateUrl: './assign-usergroups.component.html',
  styleUrls: ['./assign-usergroups.component.scss']
})
export class AssignUserGroupsComponent implements OnDestroy {
  @ViewChild('dtp', { static: true }) dataTableGroups!: Table;
  @ViewChild('dtu', { static: true }) dataTableUserGroups!: Table;

  private userId!: string;

  @Input() set user(value: string) {
    this.userId = value;
    this.loadUserGroups();
    this.loadAllGroups();
  }

  groupColumns: TableColumn[];
  userGroupColumns: TableColumn[];

  foundGroups: IGroupDTO[] = [];
  foundGroupsTotalRecords = 0;
  selectAllStateGroups = false;

  isLoadingGroups$: Observable<boolean> = this.graphService.getAllUserGroups()
    .pipe(map(groups => !groups));
  defaultSortFieldGroups = 'displayName';
  defaultSortOrderGroups = 1;

  groupSelectedItems: IGroupDTO[] = [];

  // user Table
  userGroups: IGroupDTO[] = [];
  userGroupsTotalRecords = 0;
  selectAllStateUserGroups = false;

  isLoading = false;
  isLoadingUsers = false;
  defaultSortField = 'displayName';
  defaultSortOrder = 1;
  amountOfRows = 10;
  userSelectedItems: IGroupDTO[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private readonly messageService: MessageService,
    private readonly securityService: SecurityService,
    private graphService: GraphService
  ) {
    this.groupColumns = [
      new TableColumn('User Groups Available', 'displayName', '11rem')
    ];
    this.userGroupColumns = [
      new TableColumn('User Groups Added', 'displayName', '11rem')
    ];
  }

  loadUserGroups(): void {
    if (!this.userId) {
      return;
    }

    this.isLoadingUsers = true;

    if (this.graphService.hasUserGroupsByUserId(this.userId)) {
      this.userGroups = this.graphService.getUserGroupsByUserId(this.userId);
      this.userGroupsTotalRecords = this.userGroups.length;
      this.isLoadingUsers = false;
    } else {
      this.graphService.getUserGroups(this.userId)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.isLoadingUsers = false)
        )
        .subscribe((res: ISearchGroupDTOResults) => {
          if (res) {
            this.updateUserGroups(this.userId, res.items);
          }
        });
    }
  }

  loadAllGroups(): void {
    this.graphService.getAllUserGroups()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IGroupDTO[] | null) => {
          if (res) {
            //this.foundGroups = res;
            this.foundGroups = res?.filter(x => !this.userGroups.map(y => y.groupId).includes(x.groupId)) || [];
            this.foundGroupsTotalRecords = this.foundGroups.length;
          } else {
            this.graphService.fetchAllUserGroups();
          }
        }
      );
  }

  onChangeSelectAllStateUserGroups(ev: { checked: boolean }): void {
    const value = this.dataTableUserGroups.filteredValue;
    if (ev.checked) {
      if(value){
      this.userSelectedItems = [...value.slice(0, value.length)];
      }
      else{
        this.userSelectedItems = this.dataTableUserGroups.value;
      }
    } else {
      this.userSelectedItems = [];
    }
  }

  onChangeSelectAllStateGroups(ev: { checked: boolean }): void {
    const value = this.dataTableGroups.filteredValue;
    if (ev.checked) {
      if(value){
      this.groupSelectedItems = [...value.slice(0, value.length)];
      }else{
        this.groupSelectedItems = this.dataTableGroups.value;
      }
    } else {
      this.groupSelectedItems = [];
    }
  }

  onPageChangeUserGroups(): void {
    this.resetSelectionUsers();
  }

  onPageChangeGroups(): void {
    this.resetSelectionGroups();
  }

  resetSelectionUsers(): void {
    this.selectAllStateUserGroups = false;
    this.userSelectedItems = [];
  }

  resetSelectionGroups(): void {
    this.selectAllStateGroups = false;
    this.groupSelectedItems = [];
  }

  assignGroups(): void {
    this.graphService.addUserToGroups(this.userId, this.groupSelectedItems)
      .pipe(
        catchError(() => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            key: 'error',
            detail: `Groups you selected have already been added. Please choose other Groups.`,
            life: 5000
          });
          return of(null);
        }),
        switchMap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Groups added',
            key: 'success',
            detail: `Groups you selected are successfully added.`,
            life: 5000
          });
          return this.graphService.getUserGroups(this.userId);
        }))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoadingUsers = false;
          this.resetSelectionGroups();
          this.resetSelectionUsers();
        }))
      .subscribe((res: ISearchGroupDTOResults) => {
        if (res) {
          this.updateUserGroups(this.userId, res.items);
        }
      });
  }

  onRemove(): void {
    this.graphService.removeUserFromGroups(this.userId, this.userSelectedItems)
      .pipe(switchMap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Groups removed',
          key: 'removed',
          detail: `Groups you selected are successfully removed.`,
          life: 5000
        });
        return this.graphService.getUserGroups(this.userId);
      }))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.isLoadingUsers = false;
          this.resetSelectionGroups();
          this.resetSelectionUsers();
        }))
      .subscribe((res: ISearchGroupDTOResults) => {
        if (res) {
          this.updateUserGroups(this.userId, res.items);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  rowSelectGroups(): void {
    this.selectAllStateGroups =
      this.groupSelectedItems.length === this.foundGroupsTotalRecords ||
      this.groupSelectedItems.length === this.amountOfRows;
  }

  rowUnselectGroups(): void {
    this.selectAllStateGroups = false;
  }

  rowSelectUsers(): void {
    this.selectAllStateUserGroups =
      this.userSelectedItems.length === this.userGroupsTotalRecords ||
      this.userSelectedItems.length === this.amountOfRows;
  }

  rowUnselectUsers(): void {
    this.selectAllStateUserGroups = false;
  }

  disableAssignButton(): boolean {
    return (this.securityService.hasAccess(['ExternalUser.Write'])) ? !this.groupSelectedItems.length : true;
  }

  applyFilterGroupTable(event: Event, field: string, stringVal: string): void {
    this.dataTableGroups.filter((event.target as HTMLInputElement).value, field, stringVal);
  }

  applyFilterUserGroupTable(event: Event, field: string, stringVal: string): void {
    this.dataTableUserGroups.filter((event.target as HTMLInputElement).value, field, stringVal);
  }

  public updateUserGroups(userId: string, groups: IGroupDTO[]): void {
    this.graphService.setUserGroupsByUserId(userId, groups);
    this.userGroups = groups;
    this.userGroupsTotalRecords = this.userGroups.length;
    this.loadAllGroups();
  }
}
