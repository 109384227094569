<ng-container *ngIf="shouldShowCentralizedDepartmentToggle || currentBusinessType === 'Specialty Program Provider Admin'">
  <div *ngIf="!isLoading else spinner" class="pl-2">
    <div class="flex align-items-center mb-2">
      <div class="toggle-switch" [class.disabled]="isToggleDisabled">
        <input
          type="checkbox"
          id="toggle"
          class="toggle-checkbox"
          [formControl]="isCentralizedDepartmentControl"
          (change)="toggleSwitchChanged()"
        />
        <label for="toggle" class="toggle-label"></label>
      </div>
      <div class="pl-2">Centralized Intake Department Provider Admin</div>
    </div>
  
    <div *ngIf="isToggleDisabled" class="message-container">
      <p-message
        severity="info"
        text="A user can be a Centralized Intake Department Provider Admin if they have at least one Provider of the Parent Company assigned to them."
        styleClass="custom-message"
      ></p-message>
    </div>
  
    <ng-container *ngIf="isCentralizedDepartmentControl.value && !isToggleDisabled">
      <div class="dropdown-container">
        <p-dropdown
          #companyDropdown
          [autofocusFilter]="true"
          [formControl]="companyControl"
          (onChange)="setCompany($event)"
          (onBlur)="onCompanyControlBlur()"
          styleClass="full-width"
          placeholder="Select company"
          [options]="companiesOptions"
          appendTo="body"
        ></p-dropdown>
        <div class="error-container">
          <small *ngIf="companyControl.invalid && companyControl.touched" class="p-error">Required field</small>
        </div>
      </div>
    </ng-container>
  </div>
  
  
  <ng-template #spinner>
    <div class="flex">
      <p-progressSpinner styleClass="p-progress-spinner-sm"></p-progressSpinner>
    </div>
  </ng-template>
</ng-container>