import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { AuthenticationService } from '@core/authentication/services';

import { LoginComponent } from '@core/authentication/components/login/login.component';
import { AccessDeniedComponent } from '@core/authentication/components/access-denied/access-denied.component';
import { IAuthConfig } from '@env/IEnvironment';
import { AUTH_CONFIG } from '@core/config/config.service';

export function MSALInstanceFactory(authConfig: IAuthConfig): IPublicClientApplication {
  var clientApp = new PublicClientApplication({
    ...authConfig.config,
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    }
  });

  clientApp.initialize();

  return clientApp;
}

export function MSALInterceptorConfigFactory(authConfig: IAuthConfig): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  authConfig.angularConfig.protectedResourceMap.forEach(([uri, scopes]) => {
    protectedResourceMap.set(uri, scopes);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: {
      ...authConfig.angularConfig.extraQueryParameters
    }
  };
}

export function MSALGuardConfigFactory(authConfig: IAuthConfig): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...authConfig.angularConfig.consentScopes],
      ...authConfig.angularConfig.extraQueryParameters
    }
  };
}

@NgModule({
  declarations: [
    LoginComponent,
    AccessDeniedComponent,
  ],
  imports: [
    CommonModule,
    MsalModule,
  ],
  providers: [
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AUTH_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AUTH_CONFIG],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AUTH_CONFIG],
    },
    AuthenticationService,
  ],
})
export class AuthenticationModule {}

